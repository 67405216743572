<div class="container">
  <div class="close-button" (click)="close()">
    <!-- <coin-anim-icon svgIcon="cancel" [hoverAnimate]="true"></coin-anim-icon> -->
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div></div>
    <h4 class="top-headline">
      {{ 'season-overview.notifications.headline' | translate }}
      <app-topic-action-bar class="atab"></app-topic-action-bar>
    </h4>
    <div style="padding: 2em">
      @if ((filteredTopics$ | async)?.length === 0) {
        <p>
          {{ 'season-overview.notifications.no-notifications' | translate }}
        </p>
      }
      @for (topic of filteredTopics$ | async; track topic) {
        <app-topic-item style="margin-left: 0px; margin-right: 0px" @topicItemAnimation [item]="topic" (openClick)="onTopicItemOpen($event, topic)"> </app-topic-item>
      }
    </div>
    <div class="buttons">
      <div style="flex: 1"></div>
      <coin-action-button [disabled]="(filteredTopics$ | async)?.length === 0" svgIcon="delete" (click)="deleteAllNotifications()">
        {{ 'season-overview.notifications.delete-all-notifications' | translate }}</coin-action-button
      >
      <coin-action-button class="secondary" [disabled]="(filteredTopics$ | async)?.length === 0" (click)="markAllAsRead()" svgIcon="check"
        >{{ 'season-overview.notifications.mark-all-read' | translate }}
      </coin-action-button>
    </div>
  </div>
</div>
