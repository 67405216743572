<div class="container" [class.small]="small">
  <div class="top" [ngStyle]="{ 'background-image': 'url(' + card?.backgroundUrl + ')' }">
    <h4 [innerHTML]="card?.headline"></h4>
    @if (card?.icon) {
      <div class="icon-wrapper">
        <div class="icon" [ngStyle]="{ 'background-image': 'url(' + iconUrl + ')' }"></div>
      </div>
    }
  </div>
  <div class="bottom">
    <p [innerHTML]="card?.text"></p>
    @if (card?.button) {
      <coin-action-button class="secondary" (click)="onButtonClick(card.button)">{{ card.button?.name }} </coin-action-button>
    }
  </div>
</div>
