<div id="confirmation-dialog" class="container" [style.width]="width">
  @if (data.headline) {
    <h4 data-testid="confirm-dialog-headline" class="top-header">{{ data.translate ? (data.headline | translate) : data.headline }} {{ getHeadlineData() }}</h4>
  }
  <div class="content">
    @if (!data.headline) {
      <h4 data-testid="confirm-dialog-msg">{{ data.translate ? getTranslatedValue(data.msg) : data.msg }}</h4>
    }
    @if (data.headline && data.msg) {
      <p class="msg" data-testid="confirm-dialog-msg" [innerHtml]="data.translate ? getTranslatedValue(data.msg) : data.msg"></p>
    }
    @if (data?.dropdownValues?.length) {
      <coin-dropdown
        [label]="data?.dropdownLabel"
        [(ngModel)]="selectedDropdownValue"
        [items]="data.dropdownValues"
        [shadow]="true"
        [withSearch]="data.dropdownWithSearch"
        [displayFn]="data?.dropdownDisplayFn || dropdownDisplayFn">
      </coin-dropdown>
    }
    @if (data.multilineInput) {
      <coin-textarea class="comment-text" title="text" name="text" id="text" [(ngModel)]="providedInput" placeholder="..." [disabled]="data?.inputReadOnly" />
    }
    @if (data?.provideInput && !data.multilineInput && !data.dateInput) {
      <div class="comment-text">
        <input title="text" name="text" id="text" [(ngModel)]="providedInput" placeholder="..." [readOnly]="data?.inputReadOnly" />
      </div>
    }
    @if (data?.provideInput && !data.multilineInput && data.dateInput) {
      <div class="comment-text">
        <coin-v2-date-input placeholder="..." [(ngModel)]="providedInput"> </coin-v2-date-input>
      </div>
    }

    @if (data?.noteText) {
      <div class="sub-text">
        <mat-icon>warning</mat-icon>
        <p>{{ data.translate ? (data.noteText | translate) : data.noteText }}</p>
      </div>
    }
    <coin-v2-dialog-footer color="white" padding="none">
      @if (data.cancelMsg) {
        <coin-action-button data-testid="confirm-dialog-cancel" (click)="cancel()"> {{ data.translate ? (data.cancelMsg | translate) : data.cancelMsg }}</coin-action-button>
      }
      <div class="v2-footer-spacer"></div>
      @if (data.thirdButtonMsg) {
        <coin-action-button (click)="onThirdButtonClick()" [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : false">
          {{ data.translate ? (data.thirdButtonMsg | translate) : data.thirdButtonMsg }}
        </coin-action-button>
      }
      <coin-action-button
        class="primary"
        data-testid="confirm-dialog-confirm"
        (click)="confirm()"
        [disabled]="data?.inputMandatory ? !(data.dateInput ? providedInput : providedInput?.trim()) : data.disableConfirmButton">
        {{ data.translate ? (data.confirmMsg | translate) : data.confirmMsg }}
      </coin-action-button>
    </coin-v2-dialog-footer>
  </div>
</div>
