<div class="container">
  <div class="title-bar" (click)="toggle.emit()">
    <h3>{{ 'main-menu.directs' | translate }}</h3>
    @if (isActive) {
      <coin-action-button (click)="openDirectsDialog()" class="details-btn secondary" style="margin-left: 25px">{{ 'main-menu.show-details' | translate }}</coin-action-button>
    }
    <div class="icons custom-hide-down"></div>
  </div>
  @if (isActive) {
    <div class="content">
      @if (lineManagerDirects?.length || inCompanyManagerDirects?.length) {
        <mat-tab-group [dynamicHeight]="true">
          @if (lineManagerDirects?.length) {
            <mat-tab [label]="'main-menu.line-manager' | translate">
              <ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance">
                <div class="directs" intersectionObserverList>
                  @for (direct of lineManagerDirects; track direct) {
                    <app-direct-item-overview intersectionObserverElement [direct]="direct"> </app-direct-item-overview>
                  }
                </div>
              </ngx-simplebar>
            </mat-tab>
          }
          @if (inCompanyManagerDirects?.length) {
            <mat-tab [label]="'main-menu.incompany-manager' | translate">
              <ngx-simplebar class="primary-scrollbar-vertical no-space modal-appearance">
                <div class="directs" intersectionObserverList>
                  @for (direct of inCompanyManagerDirects; track direct) {
                    <app-direct-item-overview intersectionObserverElement [direct]="direct"> </app-direct-item-overview>
                  }
                </div>
              </ngx-simplebar>
            </mat-tab>
          }
        </mat-tab-group>
      }
    </div>
  }
</div>
