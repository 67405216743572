<div class="container">
  <div class="content">
    <div class="header">
      <h3 class="fv-header-text">{{ newsTitle }}</h3>
    </div>
    <div class="main-part">
      @if (data?.type === communicationTypeEnum.Announcement) {
        <div class="announcment">
          <p>{{ data.description }}</p>
        </div>
      }
      <div class="simplebar">
        <ngx-simplebar class="primary-scrollbar-vertical equity">
          <div class="news" style="padding-right: 40px; padding-left: 40px">
            @if (cmsData) {
              <app-all-cms-news-items [item]="cmsNewsItems" [authService]="authService" style="padding-right: 20px"></app-all-cms-news-items>
            }
          </div>
          <div [hidden]="cmsData" class="news">
            <ng-template appEquityPageData></ng-template>
          </div>
        </ngx-simplebar>
      </div>
      @if (data?.type === communicationTypeEnum.News) {
        <div class="news-sidebar">
          <h4>News</h4>

          @if (filteredNewsList$ | async; as filteredNewsList) {
            <div [@listAnimation]="filteredNewsList?.length">
              @for (news of filteredNewsList; track news) {
                <div class="news-card" (click)="setNews(news)">
                  <img aria-label="topic news image" class="image-wrapper" [src]="news.mediaUrl || 'assets/equity/images/coin-logo-4x3.png'" />
                  <div class="title">
                    <p>{{ getLanguageContent(news)?.title }}</p>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
  <div class="fv-buttons">
    <coin-action-button class="secondary fv-btn fv-btn--right" (click)="close()">{{ 'general.btnClose' | translate }} </coin-action-button>
  </div>
</div>
