import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, filter, forkJoin, Observable, of, timer } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { CoinUser, LetterComment, Todo } from '@coin/shared/util-models';
import { CommentType, LetterCommentReasonEnum, TodoStateEnum, TodoTypeEnum } from '@coin/shared/util-enums';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { batchRequest, DownloadFileHelper, TinyHelpers } from '@coin/shared/util-helpers';
import { LoadingService } from '@coin/shared/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadTopics } from '../../store';
import { LetterCreationService } from '../../../shared/services/letter-creation.service';

@Component({
  selector: 'app-taf-dialog',
  templateUrl: './taf-dialog.component.html',
  styleUrls: ['./taf-dialog.component.scss']
})
export class TafDialogComponent implements OnInit {
  user$ = this.store.select<CoinUser>(UserState?.user);

  public selectedLetterIndex = 0;
  public todos: Todo[] = [];
  public todoStateEnum = TodoStateEnum;
  public todoTypeEnum = TodoTypeEnum;
  public searchText = '';
  public user: CoinUser;
  public selectedComments: LetterComment[] = [];
  public selectedTodo: Todo = null;
  public commentsVisible = false;
  public commentType = CommentType.Letter;
  public pdfData: SafeResourceUrl;

  private selectedTodos: Todo[] = [];
  private typeFilter: TodoTypeEnum[] = [TodoTypeEnum.ActiveConfirmation, TodoTypeEnum.PassiveConfirmation, TodoTypeEnum.Signature, TodoTypeEnum.HandleRejection];

  get downloadDisabled(): boolean {
    return !this.selectedTodos?.length;
  }

  get allDoneSignatureTodos(): Todo[] {
    return this.todos?.filter(todo => todo.type === TodoTypeEnum.Signature && this.getIsTodoFinished(todo));
  }

  get allDoneConfirmationTodos(): Todo[] {
    return this.todos?.filter(todo => [TodoTypeEnum.ActiveConfirmation, TodoTypeEnum.PassiveConfirmation].includes(todo.type) && this.getIsTodoFinished(todo));
  }

  get allSignatureTodos(): Todo[] {
    return this.todos?.filter(todo => todo.type === TodoTypeEnum.Signature);
  }

  get allDoneHandleRejectionTodos(): Todo[] {
    return this.todos?.filter(todo => todo.type === TodoTypeEnum.HandleRejection && this.getIsTodoFinished(todo));
  }

  get allHandleRejectionTodos(): Todo[] {
    return this.todos?.filter(todo => todo.type === TodoTypeEnum.HandleRejection);
  }

  get allConfirmationTodos(): Todo[] {
    return this.todos?.filter(todo => [TodoTypeEnum.ActiveConfirmation, TodoTypeEnum.PassiveConfirmation].includes(todo.type));
  }

  get allSelectedSignatureTodos(): Todo[] {
    return this.selectedTodos?.filter(todo => todo.type === TodoTypeEnum.Signature && !this.getIsTodoFinished(todo));
  }

  get allSelectedConfirmationTodos(): Todo[] {
    return this.selectedTodos?.filter(todo => [TodoTypeEnum.ActiveConfirmation, TodoTypeEnum.PassiveConfirmation].includes(todo.type) && !this.getIsTodoFinished(todo));
  }

  get allDoneTodos(): Todo[] {
    return this.todos?.filter(todo => this.getIsTodoFinished(todo));
  }

  get allTodosDone(): boolean {
    return this.todos?.every(todo => this.getIsTodoFinished(todo));
  }

  /// All the different types that were found in the current todos
  get allTodosTypes(): TodoTypeEnum[] {
    return [...new Set(this.todos?.map(todo => todo.type))];
  }

  public getIsTodoFinished(todo: Todo): boolean {
    return todo.state === TodoStateEnum.Done || todo.state === TodoStateEnum.Rejected;
  }

  public getIsTodoRejected(todo: Todo): boolean {
    return todo.state === TodoStateEnum.Rejected;
  }

  public getIsTodoDone(todo: Todo): boolean {
    return todo.state === TodoStateEnum.Done;
  }

  public getItemChecked(item: Todo): boolean {
    return this.selectedTodos.some(todo => todo.id === item.id);
  }

  // TODO use rxjs instead
  public getItemVisible(item: Todo): boolean {
    return this.itemSearch(item) && this.typeFilter.includes(item.type);
  }

  private itemSearch(item: Todo): boolean {
    if (!this.searchText) {
      return true;
    }

    return (
      item?.letter?.name?.toLowerCase()?.includes(this.searchText.toLowerCase()) ||
      `${item?.letter?.employee?.firstname}${item?.letter?.employee?.lastname}`?.toLowerCase()?.includes(this.searchText.toLowerCase())
    );
  }

  public getFilterActive(type: TodoTypeEnum | TodoTypeEnum[]): boolean {
    return this.typeFilter?.length && Array.isArray(type) ? this.typeFilter.some(filter => type.includes(filter)) : this.typeFilter.includes(type as TodoTypeEnum);
  }

  getLetterHasSeen(letter): boolean {
    return letter?.seenBy?.length && this.user ? letter.seenBy.includes(this.user.id) : false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { todos: Todo[] },
    private dialogRef: MatDialogRef<TafDialogComponent>,
    private loadingService: LoadingService,
    public letterCreationService: LetterCreationService,
    private store: Store,
    private dialog: MatDialog,
    private toast: ToastrService,
    private sanitize: DomSanitizer,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.todos = this.data?.todos ? TinyHelpers.nonThrowableStructuredCloneWorkaround(this.data.todos) : []; // clone-problem: MercerWorkflowInstance is a class, should not cause problems yet though
    this.updateTodos();
    this.store
      .select(UserState?.user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });

    this.setCurrentPdf();
  }

  private updateTodos(): void {
    const finishedTodos = this.todos.filter(todo => this.getIsTodoFinished(todo))?.sort((a, b) => a?.letter?.name.localeCompare(b?.letter?.name));
    const pendingTodos = this.todos.filter(todo => !this.getIsTodoFinished(todo))?.sort((a, b) => a?.letter?.name.localeCompare(b?.letter?.name));
    this.todos = [...pendingTodos, ...finishedTodos];
  }

  public close(): void {
    this.store.dispatch(new LoadTopics());
    this.dialogRef.close();
  }

  private async setCurrentPdf(): Promise<void> {
    const letter = this.todos?.[this.selectedLetterIndex]?.letter;
    const data = await this.letterCreationService.getLetterUserDocument(letter?.name, letter?.id).toPromise();

    const letterData = {
      index: this.selectedLetterIndex,
      pdf: data,
      name: letter?.name
    };

    this.showLetterPdf(letterData);
  }

  private showLetterPdf(existingLetter: { pdf: string }): void {
    if (existingLetter?.pdf) {
      this.pdfData = undefined;
      setTimeout(() => {
        this.pdfData = this.sanitize.bypassSecurityTrustResourceUrl(existingLetter.pdf.toString());
      }, 20);
    }
  }

  async download(todo: Todo): Promise<void> {
    this.loadingService.present();
    try {
      const letter = todo?.letter;
      const data = await this.letterCreationService.getLetterUserDocument(letter?.name, letter?.id).toPromise();

      if (data) {
        this.startDownload(data, letter?.name);
        this.loadingService.dismiss();
      }
    } catch (e) {
      this.loadingService.dismiss();
    }
  }

  private startDownload(base64: string, letterName: string): void {
    DownloadFileHelper.download(base64, letterName);
  }

  public confirm(todo: Todo): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.approve',
          msg: 'general.approve-msg',
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.approve',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(confirm => {
          this.loadingService.present();
          return this.confirmReq(todo).pipe(
            tap(() => {
              this.completeTodo(todo);
              this.toast.success('Approved');
            }),
            finalize(() => this.loadingService.dismiss())
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private confirmReq(todo: Todo): Observable<object> {
    return this.letterCreationService.confirmLetterTodo(todo?.letter?.id, todo?.letterWorkflow?.id);
  }

  public confirmSelected(): void {
    const requests$ = [];
    for (const todo of this.allSelectedConfirmationTodos) {
      requests$.push(this.confirmReq(todo));
    }

    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.approve',
          msgValue: this.allSelectedConfirmationTodos?.length,
          msg: `general.selected-documents-msg-value`,
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.approve',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          this.loadingService.present();
          return batchRequest(requests$, 20).pipe(
            finalize(() => this.loadingService.dismiss()),
            tap(() => {
              this.toast.success(`${this.allSelectedConfirmationTodos.length} approved.`);
              for (const todo of this.allSelectedConfirmationTodos) {
                this.completeTodo(todo);
              }
              this.selectedTodos = [];
            })
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public sign(todo: Todo): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.sign',
          msg: 'general.sign-msg',
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.sign',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          this.loadingService.present();
          return this.signReq(todo);
        }),
        switchMap(() => this.letterCreationService.createLetterUserDocument(todo?.letter?.id, todo?.letter?.name)),
        tap(() => {
          this.completeTodo(todo);
          this.toast.success('Signed');
        }),
        switchMap(() => this.setCurrentPdf()),
        finalize(() => this.loadingService.dismiss()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private signReq(todo: Todo): Observable<object> {
    return this.letterCreationService.signLetterTodo(todo?.letter?.id, todo?.letterWorkflow?.id, todo?.letter?.name);
  }

  public signSelected(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.sign',
          msgValue: this.allSelectedSignatureTodos?.length,
          msg: `general.selected-documents-msg-value`,
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.sign',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          const requests$: Observable<object>[] = [];
          for (const todo of this.allSelectedSignatureTodos) {
            requests$.push(this.signReq(todo));
          }
          this.loadingService.present();
          return batchRequest(requests$, 20);
        }),
        switchMap(() => {
          const letterUpdates = this.allSelectedSignatureTodos.map(todo => ({ letterId: todo?.letter.id, name: todo?.letter?.name }));
          const createDocuments$ = this.letterCreationService.createLetterUserDocumentBatch(letterUpdates);

          // TODO: check
          const weirdTimeoutHandler$ = timer(4000).pipe(
            switchMap(async () => {
              this.toast.success(`${this.allSelectedSignatureTodos.length} signed.`);
              for (const todo of this.allSelectedSignatureTodos) {
                this.completeTodo(todo);
              }
              await this.setCurrentPdf();
              this.selectedTodos = [];
              this.loadingService.dismiss();
            })
          );

          return forkJoin([createDocuments$, weirdTimeoutHandler$]);
        }),
        catchError(e => {
          console.error(e);
          this.loadingService.dismiss();
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private completeTodo(todo: Todo): void {
    const index = this.todos.findIndex(item => item.id === todo.id);

    this.todos[index].state = TodoStateEnum.Done;
    this.todos[index].doneDate = moment.utc().toISOString();
  }

  public reject(todo: Todo, index: number): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.reject',
          msg: 'general.reject-msg-comment',
          provideInput: true,
          inputMandatory: true,
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.reject',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(result =>
          this.letterCreationService.rejectLetterTodo(todo?.letter?.id, todo?.letterWorkflow?.id, todo?.letter?.name).pipe(
            switchMap(() => {
              const comment = new LetterComment({
                text: result?.input,
                linkedTodoId: todo?.id,
                reason: LetterCommentReasonEnum.Reject
              });
              const postComment$ = this.letterCreationService.postComment(todo?.letter?.id, comment);

              this.todos[index].state = TodoStateEnum.Rejected;
              this.todos[index].doneDate = moment.utc().toISOString();
              const setCurrentPdf$ = index === this.selectedLetterIndex ? this.setCurrentPdf() : of(null);

              return forkJoin([postComment$, setCurrentPdf$]);
            })
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public forward(todo: Todo, index: number): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.forward',
          msg: 'general.forward-msg',
          provideInput: true,
          inputMandatory: true,
          cancelMsg: 'general.go-back',
          confirmMsg: 'general.forward',
          translate: true
        }
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(result =>
          this.letterCreationService.forwardLetterTodo(todo?.letter?.id, todo?.letterWorkflow?.id).pipe(
            switchMap(() => {
              const comment = new LetterComment({
                text: result?.input,
                linkedTodoId: todo?.id,
                reason: LetterCommentReasonEnum.Forward
              });
              const postComment$ = this.letterCreationService.postComment(todo?.letter?.id, comment);

              this.todos[index].state = TodoStateEnum.Done;
              this.todos[index].doneDate = moment.utc().toISOString();
              const setCurrentPdf$ = index === this.selectedLetterIndex ? this.setCurrentPdf() : of(null);

              return forkJoin([postComment$, setCurrentPdf$]);
            })
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public downloadSelected(): void {
    for (const todo of this.selectedTodos) {
      this.download(todo);
    }
  }

  public toggleItem(checked: boolean, item): void {
    if (checked) {
      this.selectedTodos.push(item);
    } else if (this.selectedTodos?.includes(item)) {
      this.selectedTodos = this.selectedTodos.filter(todo => todo.id !== item.id);
    }
  }

  public toggleAll(event: boolean): void {
    for (const item of this.todos) {
      this.toggleItem(event, item);
    }
  }

  public toggleFilter(type?: TodoTypeEnum | TodoTypeEnum[]): void {
    this.selectedTodos = [];

    if (!type) {
      const usedTypeFilters = this.typeFilter.filter(filter => this.allTodosTypes.includes(filter));

      if (usedTypeFilters.length === this.allTodosTypes.length) {
        this.typeFilter = [];
      } else {
        this.typeFilter = this.allTodosTypes;
      }
      return;
    }

    const found = Array.isArray(type) ? this.typeFilter.some(filter => type.includes(filter)) : this.typeFilter.includes(type);
    if (found) {
      this.typeFilter = Array.isArray(type) ? this.typeFilter.filter(filter => !type.includes(filter)) : this.typeFilter.filter(filter => filter !== type);
    } else if (Array.isArray(type)) {
      this.typeFilter = [...this.typeFilter, ...type];
    } else {
      this.typeFilter.push(type);
    }
  }

  public search(event: Event): void {
    this.selectedTodos = [];
    this.searchText = (event?.target as HTMLInputElement)?.value;
  }

  public back(): void {
    if (this.selectedLetterIndex) {
      this.selectedLetterIndex--;
      this.setCurrentPdf();
    }
  }

  public next(): void {
    if (this.selectedLetterIndex < this.todos?.length - 1) {
      this.selectedLetterIndex++;
      this.setCurrentPdf();
    }
  }

  public selectOtherLetter(index: number): void {
    if (this.selectedLetterIndex !== index && !this.commentsVisible) {
      this.selectedLetterIndex = index;
      this.setCurrentPdf();
    }
  }

  public reset(): void {
    this.updateTodos();
    this.selectedTodos = [];
    this.selectedTodo = null;
    this.selectedLetterIndex = 0;
    this.setCurrentPdf();
  }

  public openComments(todo: Todo): void {
    this.selectedComments = [];
    this.selectedTodo = todo;
    this.commentsVisible = false;
    this.letterCreationService
      .getComments(todo?.letter?.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(comments => {
        this.selectedComments = comments;
        this.commentsVisible = true;
      });
  }

  public onCommentsClose(): void {
    this.commentsVisible = false;
    this.selectedTodo = null;
    this.selectedComments = [];
  }
}
