@if (user && (token.isLoggedIn$ | async)) {
  <div class="profile-main">
    @if (isEmulated) {
      <div (click)="removeEmulation()" class="emulation-icon" [class.deputy]="isLoggedInAsDeputy">
        <mat-icon>person</mat-icon>
        <p>{{ (isLoggedInAsDeputy ? 'general.stop-deputy' : 'general.stop-emulation') | translate }}</p>
      </div>
    }
    @if (userImageSrc && userImageSrc !== '') {
      <div (contextmenu)="onRightClick($event)" class="image" [ngStyle]="{ 'background-image': 'url(' + userImageSrc + ')' }"></div>
    }
    @if (!userImageSrc || userImageSrc === '') {
      <div class="avatar" slot="start">
        <mat-icon>person</mat-icon>
      </div>
    }

    <button class="profile-info no-style" [class.show-user-menu]="showMenu" (click)="openMenu()" aria-label="Open User Menu">
      <p class="orgCode">{{ userDetails?.paOrgCode ? userDetails.paOrgCode + ', ' : '' }}{{ userDetails?.placeOfAction }}</p>
      @if (userDetails) {
        <p class="bold">{{ userDetails?.firstname }} {{ userDetails?.lastNamePrefix }}{{ userDetails?.lastNamePrefix ? ' ' : '' }}{{ userDetails?.lastname }}</p>
      }
      @if (!userDetails) {
        <p class="bold">{{ user?.firstname }} {{ user?.lastname }}</p>
      }

      @if (token.remainingTimeText$ | async; as remainingTime) {
        @if (token.timeWarningStyle$ | async; as timeWarning) {
          <button
            class="remaining-time no-style"
            [class.show-yellow]="timeWarning === 'alarming'"
            [class.show-red]="timeWarning === 'urgent'"
            (mouseenter)="remainingTimeHover('enter')"
            (mouseleave)="remainingTimeHover('leave')"
            (click)="renewToken($event)"
            aria-label="Refresh User Token"
            matTooltipPosition="right">
            @if (!remainingTimeHovered) {
              <mat-icon @fade>access_time</mat-icon>
            }
            @if (remainingTimeHovered) {
              <mat-icon @slide class="renew">autorenew</mat-icon>
            }
            <p>{{ remainingTime }}</p>
          </button>
        }
      }
    </button>

    @if (showMenu) {
      <div class="menu-part-element mat-elevation-z3" @shrinkExpand>
        <div class="menu-element-item disabled">
          <div class="spacing-holder">
            <div class="border"></div>
          </div>
        </div>

        @if (isProfileAllowed) {
          <div class="menu-element-item" @shrinkExpand>
            <button (click)="profileClicked()" class="no-style spacing-holder">
              <div class="menu-entry avatar" slot="start">
                <mat-icon>person</mat-icon>
              </div>
              <p class="bold">{{ 'general.profile' | translate }}</p>
              <div class="border"></div>
            </button>
          </div>
        }

        <div class="menu-element-item" @shrinkExpand>
          <button (click)="profilePictureClicked()" class="no-style spacing-holder">
            <div class="menu-entry avatar" slot="start">
              <mat-icon>edit</mat-icon>
            </div>
            <p class="bold">{{ (!this.user || !this.userImageSrc ? 'general.add-your-picture' : 'general.modify-your-picture') | translate }}</p>
            <div class="border"></div>
          </button>
        </div>

        <div class="menu-element-item" cdkOverlayOrigin #languageTrigger="cdkOverlayOrigin">
          <button (click)="languageChangeClicked()" class="no-style spacing-holder">
            <div class="menu-entry avatar" slot="start">
              <mat-icon>language</mat-icon>
            </div>
            <p class="bold">{{ 'general.language' | translate }}</p>
            <div class="border"></div>
          </button>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="languageTrigger"
            [cdkConnectedOverlayOpen]="languageSubmenuVisible"
            cdkConnectedOverlayPanelClass="menu-overlay"
            [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
            [cdkConnectedOverlayOffsetY]="2">
            <div class="mat-elevation-z3 sub-item-container" @fade>
              <div class="spacing-holder">
                <div class="user-language-box-header">
                  @for (lang of selectableLangs | keyvalue; track lang) {
                    <div class="language-item" [ngClass]="{ active: languageSelected === lang.key }" (click)="changeLanguage($any(lang.key))">
                      <div class="lang-image" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + lang.value + '.svg)' }"></div>
                      <p>{{ 'general.' + lang.key | translate }}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ng-template>
        </div>

        @if (isAdmin) {
          <div class="menu-element-item" @shrinkExpand>
            <button (click)="translationViewerClicked()" class="no-style spacing-holder">
              <div class="menu-entry avatar" slot="start">
                <mat-icon>{{ isDebugModeEnabled ? 'visibility_off' : 'visibility' }}</mat-icon>
              </div>
              <p class="bold">{{ (isDebugModeEnabled ? 'general.disable-translation-viewer' : 'general.enable-translation-viewer') | translate }}</p>
              <div class="border"></div>
            </button>
          </div>
        }

        @if (emulationEnabled || isEmulated) {
          <div class="menu-element-item" @shrinkExpand>
            <button (click)="emulateClicked()" class="no-style spacing-holder">
              <div class="menu-entry avatar" slot="start">
                <mat-icon>{{ !isEmulated ? 'person' : 'person_off' }}</mat-icon>
              </div>
              <p class="bold">{{ (!isEmulated ? 'general.emulate' : 'general.stop-emulation') | translate }}</p>
              <div class="border"></div>
            </button>
          </div>
        }

        @if (isDeputyMenuEntryShown) {
          <div class="menu-element-item" @shrinkExpand>
            <button (click)="manageDeputiesClicked()" class="no-style spacing-holder" cdkOverlayOrigin #deputyTrigger="cdkOverlayOrigin">
              <div class="menu-entry avatar" slot="start">
                <mat-icon [svgIcon]="'manager_white'"></mat-icon>
              </div>
              <p class="bold">{{ 'general.deputies' | translate }}</p>
              <div class="border"></div>
            </button>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="deputyTrigger"
              [cdkConnectedOverlayOpen]="deputySubmenuVisible"
              cdkConnectedOverlayPanelClass="menu-overlay"
              [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
              [cdkConnectedOverlayOffsetY]="2">
              <div class="mat-elevation-z3 sub-item-container" @fade>
                <div class="spacing-holder">
                  <div class="user-language-box-header">
                    @if (!isLoggedInAsDeputy) {
                      <div class="deputy-menu-item" (click)="showDeputies()">
                        <p>{{ 'deputy.act-as' | translate }}</p>
                        <span class="material-icons">navigate_next</span>
                      </div>
                      @if (hasDirects) {
                        <div class="deputy-menu-item" (click)="openDeputySettings()">
                          <p>{{ 'deputy.settings' | translate }}</p>
                        </div>
                      }
                    }
                    @if (isLoggedInAsDeputy) {
                      <div (click)="removeEmulation()" class="language-item">
                        <div class="lang-image no-image">
                          <span class="material-icons">person_off</span>
                        </div>
                        <p>Stop acting as Deputy</p>
                      </div>
                    }

                    @if (isDeputyListVisible) {
                      <div class="mat-elevation-z3 deputy-list">
                        @for (deputy of deputies; track deputy) {
                          <div class="deputy" (click)="emulateAsDeputy(deputy)">
                            <coin-siemens-energy-employee-preview
                              [employee]="deputy"
                              [small]="true"
                              [showOrgCode]="false"
                              [showFlag]="false"></coin-siemens-energy-employee-preview>
                          </div>
                        }
                        @if (!deputies.length) {
                          <div class="deputy no-deputies">
                            <p>{{ 'deputy.no-employees' | translate }}</p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        }

        <div class="menu-element-item" @shrinkExpand>
          <button (click)="logoutClicked()" class="no-style spacing-holder">
            <div class="menu-entry avatar" slot="start">
              <mat-icon>login</mat-icon>
            </div>
            <p class="bold">{{ 'general.log-out' | translate }}</p>
          </button>
        </div>
      </div>
    }
  </div>
}

@if (!user || (token.isLoggedIn$ | async) === false) {
  <div class="profile">
    <div class="profile-image-container">
      @if (languageSelected) {
        <div class="image language-icon" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + languageSelected + '.svg)' }"></div>
      }
    </div>
    <div class="profile-info" [ngClass]="{ 'show-user-menu': showMenu }" (click)="openMenu()">
      <p class="bold">{{ 'general.' + languageSelected | translate }}</p>
    </div>
    @if (showMenu) {
      <div class="menu-part-element mat-elevation-z3" @shrinkExpand>
        <div class="menu-element-item disabled" style="height: 79px">
          <div class="spacing-holder">
            <div class="border"></div>
          </div>
        </div>
        @for (lang of selectableLangs | keyvalue; track lang; let i = $index) {
          <div class="menu-element-item" (click)="changeLanguage($any(lang.key))" @shrinkExpand>
            <div class="spacing-holder">
              @if (languageSelected) {
                <div class="image" [ngStyle]="{ 'background-image': 'url(/assets/svgs/flags/' + lang.key + '.svg)' }"></div>
              }
              <p style="margin-top: auto; margin-bottom: auto" class="bold">{{ 'general.' + lang.key | translate }}</p>
              @if (i !== menuItems.length - 1) {
                <div class="border"></div>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
<div style="flex: 1"></div>
