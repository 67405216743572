<div class="container max-width" [class.wide]="data?.options?.fullWidth">
  @if (data?.content?.sections?.length) {
    <div class="sections" [class.one-section]="data?.content?.sections?.length === 1" [ngStyle]="{ 'grid-template-columns': gridTemplateColumns }">
      @for (section of data.content.sections; track section) {
        <div>
          <p [class.bold]="data?.options?.isBold" [innerHTML]="section.text"></p>
          @if (section.oList?.length) {
            <ol>
              @for (oListItem of section.oList; track oListItem) {
                <div>
                  <li class="p bold">
                    <p [innerHTML]="oListItem?.text"></p>
                  </li>
                  @if (oListItem?.uList?.length) {
                    <div>
                      <ul>
                        @for (uListText of oListItem.uList; track uListText) {
                          <li class="p">
                            <p [innerHTML]="uListText"></p>
                          </li>
                        }
                      </ul>
                    </div>
                  }
                  @if (oListItem?.additionalText) {
                    <p class="space" [innerHTML]="oListItem.additionalText"></p>
                  }
                </div>
              }
            </ol>
          }
          @if (section.uList?.length) {
            <ul>
              @for (uListText of section.uList; track uListText) {
                <li class="p">
                  <p [innerHTML]="uListText"></p>
                </li>
              }
            </ul>
          }
        </div>
      }
    </div>
  }
  @if (data?.content?.buttons?.length) {
    <div class="buttons">
      @for (button of data.content.buttons; track button) {
        <coin-action-button [class.primary]="!!button.url" [class.secondary]="!button.url" (click)="onButtonClick(button)">
          {{ button.name }}
        </coin-action-button>
      }
    </div>
  }
</div>
