export const featureFlags = {
  exampleFlag: { Local: '✅', Dev: '✅', 'Pre-prod': '⛔', Prod: '✅' },
  documentCenterToolbar: { Local: '✅', Dev: '⛔', 'Pre-prod': '⛔', Prod: '✅' },
  customerGbrFooter: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' },
  documentCenter: { Local: '✅', Dev: '⛔', 'Pre-prod': '⛔', Prod: '✅' },
  apiTestPage: { Local: '✅', Dev: '✅', 'Pre-prod': '⛔', Prod: '⛔' },
  positionEvaluationPrePublish: { Local: '✅', Dev: '✅', 'Pre-prod': '⛔', Prod: '⛔' },
  displayEnvironment: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' },
  deputies: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' },
  headCountApprovalFeature: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' },
  sendingEmailsNotPossible: { Local: '⛔', Dev: '✅', 'Pre-prod': '⛔', Prod: '⛔' },
  specificSeasonTypes: { Local: '⛔', Dev: '⛔', 'Pre-prod': '⛔', Prod: '✅' },
  incentiveSupport: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '✅' },
  mercerStanding: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '✅' },
  mercerStandingFrozen: { Local: '⛔', Dev: '⛔', 'Pre-prod': '⛔', Prod: '✅' },
  orgManagement: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' },
  restructuring: { Local: '✅', Dev: '✅', 'Pre-prod': '⛔', Prod: '⛔' },
  newPdfRendering: { Local: '✅', Dev: '✅', 'Pre-prod': '✅', Prod: '⛔' }
} satisfies FeatureFlags;
export interface FeatureFlags {
  [key: string]: {
    Local: FeatureFlagStatus;
    Dev: FeatureFlagStatus;
    'Pre-prod': FeatureFlagStatus;
    Prod: FeatureFlagStatus;
  };
}
export type FeatureFlagName = keyof typeof featureFlags;
type FeatureFlagStatus = '✅' | '⛔';
