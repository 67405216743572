<coin-v2-dialog-frame [header]="data?.dialogTitle | translate" (closeButtonClick)="onCloseButtonClick()">
  <div class="content">
    @for (section of data?.sections; track section) {
      <div class="section-container">
        <div class="section">
          @if (section?.title) {
            <h2>{{ section?.title | translate }}</h2>
          }
          @if (section?.text) {
            <p [innerHTML]="section?.text | translate"></p>
          }
        </div>
        @if (section !== data?.sections.at(-1)) {
          <div class="divider"></div>
        }
      </div>
    }
  </div>
</coin-v2-dialog-frame>
