import { DestroyRef, Injectable } from '@angular/core';
import { MeritNewHireState, MeritPromotionState, MeritSimulationMember, MeritTerminationState } from '@coin/customer/merit-incentive/util';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { batchRequest, ITSExcelExport, TinyHelpers } from '@coin/shared/util-helpers';
import { BudgetAllocationSettings, ListViewTagFilterParameter, PaginatedResult, SeasonPlan } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { enableMapSet, produce } from 'immer';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { MeritSeasonSetting } from '@coin/admin/season-mgmt/util';
import { SeasonPlanService, SeasonSettingsService } from '@coin/admin/season-mgmt/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterSortState } from '../../../shared/components/filter-sort-bar/store/filter-sort.state';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { MeritAllocationSeason } from '../../merit-shared/models/merit-allocation-season.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';
import { MeritAllocationGeneralService } from '../../merit-shared/services/merit-allocation-general.service';
import { MeritSupportSortingParameter } from '../enums/merit-support-sorting-parameter.enum';
import { AllocationEmployeeExportDto } from '../models/merit-support-export-dto.model';
import { IncentiveSupportService } from '../services/incentive-support.service';
import { MeritSupportService } from '../services/merit-support.service';
import {
  ActivateEquityForEmployee,
  ActivateMeritForEmployee,
  AddFilterParameter,
  ApproveNewHire,
  ApproveTermination,
  ChangeSortingParameter,
  ClearFilterParameterKey,
  DeactivateEquityForEmployee,
  DeactivateMeritForEmployee,
  ExportEmployees,
  LazyLoadEmployees,
  LoadEmployeeByGid,
  LoadEmployees,
  LoadIncentiveAllocation,
  LoadSeasonAllocationSettings,
  LoadSeasonCommunicationSetting,
  LoadSeasonPlans,
  LoadSeasons,
  LoadSeasonSettings,
  LoadSimulationMemberForEmployee,
  MeritSupportChangeAllocationManager,
  PromoteSupport,
  RejectNewHire,
  RejectTermination,
  ResetSeason,
  ResetSupportState,
  RevertNewHireApproval,
  RevertNewHireRejection,
  RevertTerminationRejection,
  ReviewPromotion,
  SetEmployeeByGid,
  SetEmployeeBySearch,
  SetHasOpenInitialLoad,
  SetSeason,
  UpdateIndividualMultiplier,
  UpdateIndividualMultiplierState,
  UpdateMeritIsDoneState,
  UpdateSeasonCommunicationSetting
} from './merit-support.actions';

enableMapSet();

const DEFAULT_PAGE_SIZE = 15;

const STATE_DEFAULTS: MeritSupportStateModel = {
  season: undefined,
  seasons: [],
  seasonSettings: undefined,
  allocationSettings: undefined,
  isPromotionCommunicationGloballyEnabled: false,
  isPromotionCommunicationActive: false,
  employees: [],
  incentiveAllocations: {},
  simulationMembers: new Map(),
  sortingParameter: MeritSupportSortingParameter.Default,
  filterParameter: [],
  currentPage: 0,
  maxPage: 0,
  selectedCurrency: DisplayedCurrency.Local,
  hasOpenInitialLoad: false,
  hasLinkedIncentiveSeason: false,
  seasonPlans: []
};

interface MeritSupportStateModel {
  season: MeritAllocationSeason;
  seasons: MeritAllocationSeason[];
  seasonSettings: MeritSeasonSetting;
  allocationSettings: BudgetAllocationSettings;
  isPromotionCommunicationGloballyEnabled: boolean;
  isPromotionCommunicationActive: boolean;
  employees: EmployeeWithCompensationInfo[];
  incentiveAllocations: { [key: string]: IncentiveAllocationEmployee };
  simulationMembers: Map<string, MeritSimulationMember>;
  sortingParameter: string;
  filterParameter: ListViewTagFilterParameter[];
  currentPage: number;
  maxPage: number;
  selectedCurrency: DisplayedCurrency;
  hasOpenInitialLoad: boolean;
  hasLinkedIncentiveSeason: boolean;
  seasonPlans: SeasonPlan[];
}

@State<MeritSupportStateModel>({
  name: 'MeritSupportState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class MeritSupportState {
  constructor(
    private meritSupportService: MeritSupportService,
    private meritAllocationGeneralService: MeritAllocationGeneralService,
    private seasonSettingsService: SeasonSettingsService,
    private incentiveSupportService: IncentiveSupportService,
    private store: Store,
    private destroyRef: DestroyRef,
    private seasonPlanService: SeasonPlanService
  ) {}

  @Selector()
  static season(state: MeritSupportStateModel): MeritAllocationSeason {
    return state.season;
  }

  @Selector()
  static seasons(state: MeritSupportStateModel): MeritAllocationSeason[] {
    return state.seasons;
  }

  @Selector()
  static seasonSettings(state: MeritSupportStateModel): MeritSeasonSetting {
    return state.seasonSettings;
  }

  @Selector()
  static allocationSettings(state: MeritSupportStateModel): BudgetAllocationSettings {
    return state.allocationSettings;
  }

  @Selector()
  static isPromotionCommunicationActive(state: MeritSupportStateModel): boolean {
    return state.isPromotionCommunicationActive;
  }

  @Selector()
  static isPromotionCommunicationGloballyEnabled(state: MeritSupportStateModel): boolean {
    return state.isPromotionCommunicationGloballyEnabled;
  }

  @Selector()
  static seasonPlans(state: MeritSupportStateModel): SeasonPlan[] {
    return state.seasonPlans;
  }

  @Selector()
  static hasOpenInitialLoad(state: MeritSupportStateModel): boolean {
    return state.hasOpenInitialLoad;
  }

  @Selector()
  static employees(state: MeritSupportStateModel): EmployeeWithCompensationInfo[] {
    return state.employees;
  }

  @Selector()
  static filterParameter(state: MeritSupportStateModel): ListViewTagFilterParameter[] {
    return state.filterParameter;
  }

  @Selector()
  static selectedCurrency(state: MeritSupportStateModel): DisplayedCurrency {
    return state.selectedCurrency;
  }

  @Selector()
  static hasLinkedIncentiveSeason(state: MeritSupportStateModel): boolean {
    return state.hasLinkedIncentiveSeason;
  }

  @Selector()
  static queryText(state: MeritSupportStateModel): string {
    return MeritSupportState.getFilterParameterText(state.filterParameter);
  }

  static getSimulationMemberByEmployeeId(id: string): (state: MeritSupportStateModel) => MeritSimulationMember {
    return createSelector([MeritSupportState], (state: MeritSupportStateModel) => {
      return state.simulationMembers.get(id);
    });
  }

  static getEmployeeByEmployeeId(id: string): (state: MeritSupportStateModel) => EmployeeWithCompensationInfo {
    return createSelector([MeritSupportState], (state: MeritSupportStateModel) => {
      return state.employees.find(employee => employee.id === id);
    });
  }

  static getIncentiveAllocation(employeeId: string): (state: MeritSupportStateModel) => IncentiveAllocationEmployee {
    return createSelector([MeritSupportState], (state: MeritSupportStateModel) => {
      return state.incentiveAllocations[employeeId];
    });
  }

  private static getFilterParameterText(filterParameter: ListViewTagFilterParameter[]): string {
    let filterText = '';

    filterParameter.forEach(param => {
      filterText += `&Query.${encodeURIComponent(param.category)}=${encodeURIComponent(param.value)}`;
    });

    return filterText ?? '';
  }

  private getSortingParameterText(ctx: StateContext<MeritSupportStateModel>): string {
    return ctx.getState().sortingParameter !== MeritSupportSortingParameter.Default ? `&Sorting.Property=${ctx.getState().sortingParameter}` : '';
  }

  @Action(LoadSeasons)
  loadSeasons(ctx: StateContext<MeritSupportStateModel>): Observable<void> {
    return this.meritAllocationGeneralService.getMeritPartnerSeasons().pipe(
      tap(seasons => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.seasons = seasons;
          })
        );
      }),
      filter(seasons => seasons?.length === 1),
      switchMap(seasons => ctx.dispatch(new SetSeason(seasons[0])))
    );
  }

  @Action(LoadSeasonSettings)
  loadSeasonSettings(ctx: StateContext<MeritSupportStateModel>, { payload }: LoadSeasonSettings): Observable<MeritSeasonSetting> {
    return this.seasonSettingsService.getSeasonSettings(payload.id, payload.type).pipe(
      tap((settings: MeritSeasonSetting) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.seasonSettings = settings;
            state.hasLinkedIncentiveSeason = !!settings.incentiveSeasonId;
          })
        );
      })
    );
  }

  @Action(LoadSeasonAllocationSettings)
  loadSeasonAllocationSettings(ctx: StateContext<MeritSupportStateModel>, { payload }: LoadSeasonAllocationSettings): Observable<BudgetAllocationSettings> {
    return this.meritAllocationGeneralService.getAllocationSettings(payload.seasonId).pipe(
      tap((settings: BudgetAllocationSettings) => {
        ctx.patchState({ allocationSettings: settings, isPromotionCommunicationGloballyEnabled: !!settings.promotionRequestedMailTemplateId });
      })
    );
  }

  @Action(LoadSeasonCommunicationSetting)
  loadSeasonCommunicationSetting(ctx: StateContext<MeritSupportStateModel>, { payload }: LoadSeasonCommunicationSetting): Observable<{ isPromotionCommunicationActive: boolean }> {
    return this.meritSupportService.getMeritSupportCommunicationSetting(payload.seasonId).pipe(
      tap(response => {
        if (!response) {
          ctx.patchState({ isPromotionCommunicationActive: false, isPromotionCommunicationGloballyEnabled: false });
        } else {
          ctx.patchState({ isPromotionCommunicationActive: response.isPromotionCommunicationActive });
        }
      })
    );
  }

  @Action(UpdateSeasonCommunicationSetting)
  updateSeasonCommunicationSetting(ctx: StateContext<MeritSupportStateModel>, { payload }: UpdateSeasonCommunicationSetting): Observable<void> {
    return this.meritSupportService.updateMeritSupportCommunicationSetting(payload.seasonId, payload.isPromotionCommunicationActive).pipe(
      tap(() => {
        ctx.patchState({ isPromotionCommunicationActive: payload.isPromotionCommunicationActive });
      })
    );
  }

  @Action(LoadSeasonPlans)
  loadSeasonPlans(ctx: StateContext<MeritSupportStateModel>, { seasonId }: LoadSeasonPlans): Observable<SeasonPlan[]> {
    return this.seasonPlanService.getSeasonPlans(seasonId).pipe(
      tap((seasonPlans: SeasonPlan[]) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.seasonPlans = seasonPlans;
          })
        );
      })
    );
  }

  @Action(SetSeason)
  setSeason(ctx: StateContext<MeritSupportStateModel>, { payload }: SetSeason): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.season = payload;
      })
    );
  }

  @Action(ResetSeason)
  resetSeason(ctx: StateContext<MeritSupportStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.season = undefined;
      })
    );
  }

  @Action(SetHasOpenInitialLoad)
  setHasOpenInitialLoad(ctx: StateContext<MeritSupportStateModel>, { payload }: SetHasOpenInitialLoad): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.hasOpenInitialLoad = payload;
      })
    );
  }

  @Action(LoadEmployees)
  loadEmployees(ctx: StateContext<MeritSupportStateModel>): Observable<PaginatedResult<EmployeeWithCompensationInfo>> {
    const seasonId = ctx.getState().season.id;
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);

    filterText += MeritSupportState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);
    return this.meritSupportService.getEmployees(seasonId, 0, DEFAULT_PAGE_SIZE, filterText).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.employees = result.content;
            state.currentPage = 0;
            state.maxPage = result.pageCount - 1;
          })
        );
      })
    );
  }

  @Action(LoadEmployeeByGid)
  loadEmployeeByGid(ctx: StateContext<MeritSupportStateModel>, { gid }: LoadEmployeeByGid): Observable<EmployeeWithCompensationInfo> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService.getEmployeeByGid(seasonId, gid).pipe(
      map(result => result?.content?.[0]),
      tap(loadedEmployee => {
        if (loadedEmployee) {
          ctx.setState(
            produce(ctx.getState(), state => {
              const employeeIndex = state.employees.findIndex(employee => employee.id === loadedEmployee.id);
              if (employeeIndex > -1) {
                state.employees[employeeIndex] = loadedEmployee;
              } else {
                state.employees.push(loadedEmployee);
              }
            })
          );
        }
      })
    );
  }

  @Action(ExportEmployees)
  exportEmployees(ctx: StateContext<MeritSupportStateModel>): void {
    const seasonId = ctx.getState().season.id;
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += MeritSupportState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);

    let fullResult: AllocationEmployeeExportDto[] = [];
    const batchSize = 3000;
    this.meritSupportService
      .getEmployeesToExport(seasonId, filterText, 0, batchSize)
      .pipe(
        switchMap(firstFetch => {
          fullResult = firstFetch.content;

          if (firstFetch?.currentPage < firstFetch?.pageCount) {
            const req = [...Array(firstFetch.pageCount).keys()]
              .filter(page => page !== 0)
              .map(page => this.meritSupportService.getEmployeesToExport(seasonId, filterText, page, batchSize));

            return batchRequest(req, req?.length).pipe(
              filter(data => !!data),
              tap(data => fullResult.push(...data.flatMap(res => res?.content || []))),
              tap(() => this.performExcelDownload(fullResult))
            );
          }

          this.performExcelDownload(fullResult);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private performExcelDownload(result: AllocationEmployeeExportDto[]): void {
    const header = Object.keys(result[0]).map(e => TinyHelpers.pascalcaseToText(e));
    ITSExcelExport.exportExcel(result, header, `merit-support-current-view`, {
      columnFormats: [{ column: 1, format: '00000' }],
      percentageColumns: [],
      numberColumns: [3, 4, 5],
      validations: []
    });
  }

  @Action(LazyLoadEmployees)
  lazyLoadEmployees(ctx: StateContext<MeritSupportStateModel>): Observable<PaginatedResult<EmployeeWithCompensationInfo>> {
    const seasonId = ctx.getState().season.id;
    const { currentPage, maxPage } = ctx.getState();

    if (currentPage >= maxPage) {
      return;
    }
    let filterText = this.store.selectSnapshot(FilterSortState.getSortAndFilterText);
    filterText += MeritSupportState.getFilterParameterText(ctx.getState().filterParameter) + this.getSortingParameterText(ctx);
    return this.meritSupportService.getEmployees(seasonId, currentPage + 1, DEFAULT_PAGE_SIZE, filterText).pipe(
      tap(result => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const employees = [...state.employees, ...result.content];

            state.employees = employees?.filter((employee, index) => employees.findIndex(e => e.id === employee.id) === index); // delete all duplicates
            state.currentPage += 1;
          })
        );
      })
    );
  }

  @Action(SetEmployeeBySearch)
  setEmployeeBySearch(ctx: StateContext<MeritSupportStateModel>, { payload }: SetEmployeeBySearch): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const employeesWithoutSearchedEmployee = state.employees.filter(employee => employee.id !== payload.id);
        state.employees = [payload, ...employeesWithoutSearchedEmployee];
      })
    );
  }

  @Action(SetEmployeeByGid)
  setEmployeeByGid(ctx: StateContext<MeritSupportStateModel>, { payload }: SetEmployeeByGid): Observable<void> {
    return this.meritSupportService.getSearchItemsByText(payload, 0, ctx.getState().season.id).pipe(
      filter(data => data?.length === 1),
      switchMap(employees => ctx.dispatch(new SetEmployeeBySearch(employees[0])))
    );
  }

  @Action(ChangeSortingParameter)
  changeSortingParameter(ctx: StateContext<MeritSupportStateModel>, { payload }: ChangeSortingParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        if (state.sortingParameter === payload) {
          return;
        }
        state.sortingParameter = payload;
        ctx.dispatch(new LoadEmployees());
      })
    );
  }

  @Action(AddFilterParameter)
  changeFilterParameter(ctx: StateContext<MeritSupportStateModel>, { payload }: AddFilterParameter): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter.push(payload);
      })
    );
  }

  @Action(ClearFilterParameterKey)
  clearFilterParameterKey(ctx: StateContext<MeritSupportStateModel>, { category }: ClearFilterParameterKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.filterParameter = state.filterParameter.filter(param => param.category !== category);
      })
    );
  }

  @Action(LoadSimulationMemberForEmployee)
  loadSimulationMemberForEmployee(ctx: StateContext<MeritSupportStateModel>, { employeeId }: LoadSimulationMemberForEmployee): Observable<MeritSimulationMember> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService.getSimulationMember(seasonId, employeeId).pipe(
      tap(simulationMember => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.simulationMembers.set(employeeId, simulationMember);
          })
        );
      }),
      catchError(e => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.simulationMembers.delete(employeeId);
          })
        );
        return throwError(e);
      })
    );
  }

  @Action(ActivateMeritForEmployee)
  activateMeritForEmployee(ctx: StateContext<MeritSupportStateModel>, { payload }: ActivateMeritForEmployee): Observable<void> {
    return this.meritSupportService
      .activate({
        employeeId: payload.employeeId,
        seasonId: ctx.getState().season.id,
        meritSettingsId: payload.meritPlan.id
      })
      .pipe(
        delay(500),
        switchMap(() => {
          return this.meritSupportService.getSimulationMember(ctx.getState().season.id, payload.employeeId).pipe(
            tap(result => {
              ctx.setState(
                produce(ctx.getState(), state => {
                  state.simulationMembers.set(payload.employeeId, result);
                })
              );
            }),
            mergeMap(() => ctx.dispatch(new LoadEmployeeByGid(payload.gid)))
          );
        })
      );
  }

  @Action(ActivateEquityForEmployee)
  activateEquityForEmployee(ctx: StateContext<MeritSupportStateModel>, { payload }: ActivateEquityForEmployee): Observable<void> {
    return this.meritSupportService
      .activate({
        employeeId: payload.employeeId,
        seasonId: ctx.getState().season.id,
        equitySettingsId: payload.equityPlan.id,
        equitySubSettingsId: payload.equityPlanDetails?.id,
        mercerPositionClass: payload.positionClass
      })
      .pipe(
        delay(1000),
        switchMap(() => {
          return this.meritSupportService.getSimulationMember(ctx.getState().season.id, payload.employeeId).pipe(
            tap(result => {
              ctx.setState(
                produce(ctx.getState(), state => {
                  state.simulationMembers.set(payload.employeeId, result);
                })
              );
            }),
            mergeMap(() => ctx.dispatch(new LoadEmployeeByGid(payload.gid)))
          );
        })
      );
  }

  @Action(DeactivateMeritForEmployee)
  deactivateMeritForEmployee(ctx: StateContext<MeritSupportStateModel>, { payload }: DeactivateMeritForEmployee): Observable<MeritSimulationMember> {
    const seasonId = ctx.getState().season.id;

    const body = {
      deactivateMerit: true,
      meritSettingsComment: payload.comment
    };

    return this.meritSupportService.deactivate(seasonId, payload.employeeId, body).pipe(
      tap(res => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.simulationMembers.set(payload.employeeId, res);
          })
        );
      })
    );
  }

  @Action(DeactivateEquityForEmployee)
  deactivateEquityForEmployee(ctx: StateContext<MeritSupportStateModel>, { payload }: DeactivateEquityForEmployee): Observable<MeritSimulationMember> {
    const seasonId = ctx.getState().season.id;

    const body = {
      deactivateEquity: true,
      equitySettingsComment: payload.comment
    };

    return this.meritSupportService.deactivate(seasonId, payload.employeeId, body).pipe(
      tap(res => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.simulationMembers.set(payload.employeeId, res);
          })
        );
      })
    );
  }

  @Action(ReviewPromotion)
  reviewPromotion(ctx: StateContext<MeritSupportStateModel>, { payload }: ReviewPromotion): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService.reviewPromotion(seasonId, payload.employeeId, payload.state).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const currentMember = state.simulationMembers.get(payload.employeeId);
            state.simulationMembers.set(payload.employeeId, { ...currentMember, promotion: payload.state });
          })
        );
      })
    );
  }

  @Action(PromoteSupport)
  promote(ctx: StateContext<MeritSupportStateModel>, { payload }: PromoteSupport): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService.promoteEmployee(seasonId, payload.employeeId, payload.comment).pipe(
      tap(() => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const currentMember = state.simulationMembers.get(payload.employeeId);
            state.simulationMembers.set(payload.employeeId, { ...currentMember, promotion: MeritPromotionState.Requested });
          })
        );
      })
    );
  }

  @Action(RejectNewHire)
  rejectNewHire(ctx: StateContext<MeritSupportStateModel>, { employeeId }: RejectNewHire): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .rejectNewHire(seasonId, employeeId, {
        employeeId: employeeId,
        seasonId,
        revert: false
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(employeeId);
              state.simulationMembers.set(employeeId, { ...currentMember, newHire: MeritNewHireState.Rejected });
            })
          );
        })
      );
  }

  @Action(RevertNewHireRejection)
  revertNewHireRejection(ctx: StateContext<MeritSupportStateModel>, { employeeId }: RevertNewHireRejection): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .rejectNewHire(seasonId, employeeId, {
        employeeId: employeeId,
        seasonId,
        revert: true
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(employeeId);
              state.simulationMembers.set(employeeId, { ...currentMember, newHire: MeritNewHireState.ForReview });
            })
          );
        })
      );
  }

  @Action(RejectTermination)
  rejectTermination(ctx: StateContext<MeritSupportStateModel>, { employeeId }: RejectTermination): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .rejectTermination(seasonId, employeeId, {
        employeeId: employeeId,
        seasonId,
        revert: false
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(employeeId);
              state.simulationMembers.set(employeeId, { ...currentMember, termination: MeritTerminationState.Rejected });
            })
          );
        })
      );
  }

  @Action(RevertTerminationRejection)
  revertTerminationRejection(ctx: StateContext<MeritSupportStateModel>, { employeeId }: RevertTerminationRejection): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .rejectTermination(seasonId, employeeId, {
        employeeId: employeeId,
        seasonId,
        revert: true
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(employeeId);
              state.simulationMembers.set(employeeId, { ...currentMember, termination: MeritTerminationState.ForReview });
            })
          );
        })
      );
  }

  @Action(ApproveTermination)
  approveTermination(ctx: StateContext<MeritSupportStateModel>, { employeeId }: ApproveTermination): Observable<MeritSimulationMember> {
    const seasonId = ctx.getState().season.id;

    const body = {
      shouldTerminate: true
    };

    return this.meritSupportService.deactivate(seasonId, employeeId, body).pipe(
      tap(res => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.simulationMembers.set(employeeId, res);
          })
        );
      })
    );
  }

  @Action(ApproveNewHire)
  approveNewHire(ctx: StateContext<MeritSupportStateModel>, { payload }: ApproveNewHire): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .approveNewHire(seasonId, payload.employeeId, {
        seasonId,
        employeeId: payload.employeeId,
        mercerPositionClass: payload.positionClass,
        equitySettingsId: payload.equityPlan?.id,
        equitySubSettingsId: payload.equityPlanDetails?.id,
        meritSettingsId: payload.meritPlan?.id,
        revert: false
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(payload.employeeId);
              state.simulationMembers.set(payload.employeeId, { ...currentMember, newHire: MeritNewHireState.Approved });
            })
          );
        })
      );
  }

  @Action(RevertNewHireApproval)
  revertNewHireApproval(ctx: StateContext<MeritSupportStateModel>, { employeeId }: RevertNewHireApproval): Observable<void> {
    const seasonId = ctx.getState().season.id;

    return this.meritSupportService
      .approveNewHire(seasonId, employeeId, {
        seasonId,
        employeeId,
        revert: true
      })
      .pipe(
        tap(() => {
          ctx.setState(
            produce(ctx.getState(), state => {
              const currentMember = state.simulationMembers.get(employeeId);
              state.simulationMembers.set(employeeId, { ...currentMember, newHire: MeritNewHireState.ForReview });
            })
          );
        })
      );
  }

  @Action(MeritSupportChangeAllocationManager)
  public meritSupportChangeAllocationManager(ctx: StateContext<MeritSupportStateModel>, { payload }: MeritSupportChangeAllocationManager): Observable<MeritSimulationMember> {
    return this.meritSupportService
      .changeAllocationManager(ctx.getState().season.id, payload.employeeId, {
        newAllocationManagerId: payload.newAllocationManagerId,
        newLineManagerId: payload.newLineManagerId,
        newInCompanyManagerId: payload.newInCompanyManagerId
      })
      .pipe(
        delay(500),
        switchMap(() => this.meritSupportService.getSimulationMember(ctx.getState().season.id, payload.employeeId)),
        tap(simulationMember => {
          ctx.setState(
            produce(ctx.getState(), state => {
              state.simulationMembers.set(payload.employeeId, simulationMember);
            })
          );
        })
      );
  }

  @Action(ResetSupportState)
  resetSupportState(ctx: StateContext<MeritSupportStateModel>): void {
    ctx.setState(produce(ctx.getState(), () => STATE_DEFAULTS));
  }

  @Action(LoadIncentiveAllocation)
  loadIncentiveAllocation(ctx: StateContext<MeritSupportStateModel>, { employeeId }: LoadIncentiveAllocation): Observable<IncentiveAllocationEmployee> {
    const seasonId = ctx.getState().season.id;
    return this.incentiveSupportService.getIncentiveAllocation(seasonId, employeeId).pipe(
      tap(employee => {
        ctx.setState(
          produce(ctx.getState(), draftState => {
            draftState.incentiveAllocations[employeeId] = employee;
          })
        );
      })
    );
  }

  @Action(UpdateIndividualMultiplier)
  updateIndividualMultiplier(ctx: StateContext<MeritSupportStateModel>, { employeeId, multiplier }: UpdateIndividualMultiplier): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const incentiveAllocation = state.incentiveAllocations[employeeId] as IncentiveAllocationEmployee;
        incentiveAllocation.individualMultiplier = multiplier;
        state.incentiveAllocations[employeeId] = incentiveAllocation;
      })
    );

    ctx.dispatch(new LoadSimulationMemberForEmployee(employeeId));
  }

  @Action(UpdateIndividualMultiplierState)
  updateIndividualMultiplierState(ctx: StateContext<MeritSupportStateModel>, { employeeId, multiplierState }: UpdateIndividualMultiplierState): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const simulationMember = state.simulationMembers.get(employeeId) as MeritSimulationMember;
        simulationMember.individualMultiplierState = multiplierState;
        state.simulationMembers.set(employeeId, simulationMember);

        const incentiveAllocation = state.incentiveAllocations[employeeId] as IncentiveAllocationEmployee;
        incentiveAllocation.individualMultiplierState = multiplierState;
        state.incentiveAllocations[employeeId] = incentiveAllocation;
      })
    );
  }

  @Action(UpdateMeritIsDoneState)
  updateMeritIsDoneState(ctx: StateContext<MeritSupportStateModel>, { employeeId, isDone }: UpdateMeritIsDoneState): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const currentMember = state.simulationMembers.get(employeeId);
        state.simulationMembers.set(employeeId, { ...currentMember, isDone });
      })
    );
  }
}
