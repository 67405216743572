<div class="faq-list-item-container">
  @for (item of items; track item) {
    @if (authRequired(item)) {
      <button class="no-style faq-list-item faq-padding arrow--right faq-list-item-button" (click)="openFaqItem(item, 1, item?.generalName)">
        @if (item?.['icon']) {
          <div>
            <span class="material-icons-outlined faq-icon">{{ item?.['icon] }}</span>
          </div>
        }
        <p class="faq-list-item-text">
          <span class="item-title" [matBadge]="item?.newFlagCount" [matBadgeHidden]="item?.newFlagCount === 0" matBadgeOverlap="false">{{ item?.title }}</span>
        </p>
        <div class="faq-topic-border"></div>
      </button>
    }
  }
</div>
