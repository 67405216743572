<div class="container">
  <div class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div class="header">
      <h3 class="fv-header-text">{{ 'taf-dialog.main-title' | translate }}</h3>
      @if (allSignatureTodos?.length || allConfirmationTodos?.length) {
        <div class="filter-buttons">
          <button class="button" (click)="toggleFilter()">
            <p>{{ 'general.all' | translate }}</p>
            <div class="badge">
              <span>{{ allDoneTodos?.length }} / {{ todos?.length }}</span>
            </div>
          </button>
          @if (allSignatureTodos?.length) {
            <button class="button" (click)="toggleFilter(todoTypeEnum.Signature)" [class.sign]="getFilterActive(todoTypeEnum.Signature)">
              <mat-icon>how_to_reg</mat-icon>
              <p>{{ 'taf-dialog.sign' | translate }}</p>
              <div class="badge">
                <span>{{ allDoneSignatureTodos?.length }} / {{ allSignatureTodos?.length }}</span>
              </div>
            </button>
          }
          @if (allHandleRejectionTodos?.length) {
            <button class="button button--handle-rejection" (click)="toggleFilter(todoTypeEnum.HandleRejection)" [class.sign]="getFilterActive(todoTypeEnum.HandleRejection)">
              <mat-icon>priority_high</mat-icon>
              <p>{{ 'taf-dialog.handle-rejection' | translate }}</p>
              <div class="badge">
                <span>{{ allDoneHandleRejectionTodos?.length }} / {{ allHandleRejectionTodos?.length }}</span>
              </div>
            </button>
          }
          @if (allConfirmationTodos?.length) {
            <button
              class="button"
              (click)="toggleFilter([todoTypeEnum.ActiveConfirmation, todoTypeEnum.PassiveConfirmation])"
              [class.confirm]="getFilterActive([todoTypeEnum.ActiveConfirmation, todoTypeEnum.PassiveConfirmation])">
              <mat-icon>verified_user</mat-icon>
              <p>{{ 'taf-dialog.approve' | translate }}</p>
              <div class="badge">
                <span>{{ allDoneConfirmationTodos?.length }} / {{ allConfirmationTodos?.length }}</span>
              </div>
            </button>
          }
        </div>
      }
      <mat-icon class="check" [class.visible]="allTodosDone">check_circle</mat-icon>
    </div>
    <div class="main-part">
      <div class="left" id="letterContainer">
        @if (pdfData) {
          <embed [src]="pdfData" style="height: 100%" type="application/pdf" />
        }
        @if (!pdfData) {
          <app-turbine></app-turbine>
        }
      </div>
      <div class="right">
        <div class="top">
          <div class="selection">
            <coin-v2-checkbox (ngModelChange)="toggleAll($event)"></coin-v2-checkbox>
            <p>{{ 'taf-dialog.select-all' | translate }}</p>
          </div>
          <div class="input-wrapper">
            <input (input)="search($event)" />
            <mat-icon>search</mat-icon>
          </div>
        </div>
        <div class="items">
          @for (todo of todos; track todo; let i = $index) {
            <div
              class="letter-item mat-elevation-z4"
              [class.is-highlighted]="i === selectedLetterIndex"
              (click)="selectOtherLetter(i)"
              [class.hidden]="!getItemVisible(todo)"
              [class.disabled]="getIsTodoFinished(todo)">
              <div>
                <coin-v2-checkbox (onModelChange)="toggleItem($event, todo)" [ngModel]="getItemChecked(todo)"> </coin-v2-checkbox>
                <coin-siemens-energy-employee-preview [employee]="todo?.letter?.employeeId | employeeId$ | async"> </coin-siemens-energy-employee-preview>
                <p class="bold letter-name">{{ todo?.letter?.name | fileName }}</p>
              </div>
              <div>
                <div class="todo-actions">
                  @if (todo.type === todoTypeEnum.Signature && !getIsTodoRejected(todo)) {
                    <button (click)="sign(todo)" [disabled]="getIsTodoFinished(todo)">
                      <mat-icon class="workflow-icon workflow-icon--sign">how_to_reg</mat-icon>
                      @if (!getIsTodoFinished(todo)) {
                        <span>{{ 'taf-dialog.sign' | translate }}</span>
                      }
                      @if (getIsTodoFinished(todo)) {
                        <span>{{ todo?.doneDate | date: 'MM/dd/yyyy' }}</span>
                      }
                    </button>
                  }
                  @if ((todo.type === todoTypeEnum.ActiveConfirmation || todo.type === todoTypeEnum.PassiveConfirmation) && !getIsTodoRejected(todo)) {
                    <button (click)="confirm(todo)" [disabled]="getIsTodoFinished(todo)">
                      <mat-icon class="workflow-icon workflow-icon--confirm">done</mat-icon>
                      @if (!getIsTodoFinished(todo)) {
                        <span>{{ 'taf-dialog.approve' | translate }}</span>
                      }
                      @if (getIsTodoFinished(todo)) {
                        <span>{{ todo?.doneDate | date: 'MM/dd/yyyy' }}</span>
                      }
                    </button>
                  }
                  @if (todo.type === todoTypeEnum.HandleRejection && !getIsTodoRejected(todo)) {
                    <button (click)="forward(todo, i)" [disabled]="getIsTodoFinished(todo)">
                      <mat-icon class="workflow-icon workflow-icon--forward">forward</mat-icon>
                      @if (!getIsTodoFinished(todo)) {
                        <span>{{ 'taf-dialog.forward' | translate }}</span>
                      }
                      @if (getIsTodoFinished(todo)) {
                        <span>{{ todo?.doneDate | date: 'MM/dd/yyyy' }}</span>
                      }
                    </button>
                  }
                  @if (!getIsTodoDone(todo)) {
                    <button (click)="reject(todo, i)" [disabled]="getIsTodoFinished(todo)">
                      <mat-icon class="workflow-icon workflow-icon--reject">close</mat-icon>
                      @if (!getIsTodoFinished(todo)) {
                        <span>{{ 'general.reject' | translate }}</span>
                      }
                      @if (getIsTodoFinished(todo)) {
                        <span>{{ todo?.doneDate | date: 'MM/dd/yyyy' }}</span>
                      }
                    </button>
                  }
                </div>
                <div class="overall-actions">
                  <button (click)="openComments(todo)">
                    <mat-icon>comment</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
        @if (commentsVisible) {
          <coin-siemens-energy-comments
            [commentType]="commentType"
            [comments]="selectedComments"
            (closeMe)="onCommentsClose()"
            [todo]="selectedTodo"
            [user]="user$ | async"
            [commentService]="letterCreationService"
            [letterId]="selectedTodo?.letter?.id">
          </coin-siemens-energy-comments>
        }
      </div>
    </div>
  </div>
  <div class="fv-buttons">
    <div class="left">
      <coin-action-button (click)="back()" svgIcon="backward" arrowPos="left" [disabled]="selectedLetterIndex === 0">
        {{ 'general.btnBack' | translate }}
      </coin-action-button>
      <p class="bold">{{ selectedLetterIndex + 1 }} / {{ todos?.length }}</p>
      <coin-action-button (click)="next()" svgIcon="forward" [disabled]="selectedLetterIndex === todos?.length - 1">
        {{ 'general.btnNext' | translate }}
      </coin-action-button>
    </div>
    <div class="right">
      <coin-action-button class="secondary" (click)="downloadSelected()" [disabled]="downloadDisabled">
        {{ 'general.download' | translate }}
      </coin-action-button>
      <coin-action-button class="primary" (click)="confirmSelected()" [disabled]="!allSelectedConfirmationTodos?.length" matIcon="verified_user" [noicon]="true">
        {{ 'general.approve' | translate }}
      </coin-action-button>
      <coin-action-button class="primary" (click)="signSelected()" [disabled]="!allSelectedSignatureTodos?.length" matIcon="how_to_reg" [noicon]="true">
        {{ 'general.sign' | translate }}
      </coin-action-button>
    </div>
  </div>
</div>
