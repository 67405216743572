import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, Inject, OnInit, Type, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CmsContentItemContent } from '@coin/admin/cms/util';
import { AuthService } from '@coin/modules/auth/data-access';
import { CommunicationTypeEnum, ContentLanguage, EquityElementTypeEnum } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { CommunicationContent, CommunicationContentElements, Topic, TopicType } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, EMPTY, Observable, switchMap } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { DynamicSpacerComponent } from '../../../equity/components/dynamic-spacer/dynamic-spacer.component';
import { EquityCardsComponent } from '../../../equity/components/equity-cards/equity-cards.component';
import { EquityElementNotFoundComponent } from '../../../equity/components/equity-element-not-found/equity-element-not-found.component';
import { EquityHeadlineComponent } from '../../../equity/components/equity-headline/equity-headline.component';
import { EquityMediaComponent } from '../../../equity/components/equity-media/equity-media.component';
import { EquitySubTitleComponent } from '../../../equity/components/equity-sub-title/equity-sub-title.component';
import { EquityTextComponent } from '../../../equity/components/equity-text/equity-text.component';
import { EquityTilesComponent } from '../../../equity/components/equity-tiles/equity-tiles.component';
import { EquityPageDataDirective } from '../../../equity/directives/equity-page-data.directive';
import { EquityPageComponentModel } from '../../../equity/models/equity-page-component.model';
import { TopicsState } from '../../../home/store';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        // each time the binding value changes
        query(':leave', [animate('100ms', style({ opacity: 0 }))], { optional: true }),
        query(':enter', [style({ transform: 'translateX(80%)', opacity: 0 }), stagger(80, [animate('200ms ease-in', style({ transform: 'translateX(0)', opacity: 1 }))])], {
          optional: true
        })
      ])
    ])
  ]
})
export class NewsPopupComponent implements OnInit {
  public allNews$: Observable<Topic[]> = null;

  public newsTitle: string;
  public shownNews$: BehaviorSubject<Topic> = new BehaviorSubject(null);
  public filteredNewsList$: Observable<Topic[]> = null;
  public communicationTypeEnum = CommunicationTypeEnum;
  public cmsNewsItems: CmsContentItemContent = [] as never;
  public cmsData = false;

  @ViewChild(EquityPageDataDirective, { static: true }) equityPageDataHost: EquityPageDataDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dto: Topic; title: string; description: string; type: TopicType },
    private dialogRef: MatDialogRef<NewsPopupComponent>,
    private store: Store,
    private translateService: TranslateService,
    private http: HttpClient,
    private destroyRef: DestroyRef,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.newsTitle = this.data.title;
    this.shownNews$.next(this.data.dto);
    this.allNews$ = this.store.select(TopicsState.topics).pipe(map(items => items.filter(item => item.type === CommunicationTypeEnum.News)));
    this.setFilteredNewsList();

    this.shownNews$
      .pipe(
        map(topic => this.getLanguageContent(topic)),
        tap(content => {
          this.newsTitle = content.title;
        }),
        map(content => this.getLanguageElements(content)),
        switchMap(data => {
          if (data.useCmsNews || (!data.useOldNews && data.cmsItemUsed)) {
            return this.http.get<string | Record<ContentLanguage, CmsContentItemContent>>(`${environment.cmsContentStageBucketCloudfront}${data.cmsItemUsed}`).pipe(
              map(content => (typeof content === 'string' ? (JSON.parse(content) as Record<ContentLanguage, CmsContentItemContent>) : content)),
              tap(file => {
                this.cmsNewsItems = this.getCmsLanguageContent(file);
                this.cmsData = true;
              })
            );
          }

          this.cmsData = false;
          this.loadComponents(data);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private setFilteredNewsList(): void {
    this.filteredNewsList$ = null;
    this.filteredNewsList$ = combineLatest([this.allNews$, this.shownNews$])
      .pipe(
        map(([news, currentNews]) => news.filter(item => item.id !== currentNews.id)),
        delay(100)
      )
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  private loadComponents(data: CommunicationContentElements): void {
    if (!data.elements?.length) {
      return;
    }
    this.equityPageDataHost.viewContainerRef.clear();
    for (const element of data.elements) {
      const matchingDomElement = this.getMatchingComponentInstance(element.type);
      this.appendElementToDom(matchingDomElement, element);
    }
  }

  private appendElementToDom(domElement: Type<unknown>, data: CommunicationContentElements['elements'][number]): void {
    const { viewContainerRef } = this.equityPageDataHost;
    const componentRef = viewContainerRef.createComponent(domElement);

    if (data) {
      (componentRef.instance as EquityPageComponentModel).data = data;
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getMatchingComponentInstance(type: EquityElementTypeEnum): Type<unknown> {
    switch (type) {
      case EquityElementTypeEnum.HEADLINE:
        return EquityHeadlineComponent;
      case EquityElementTypeEnum.SUB_TITLE:
        return EquitySubTitleComponent;
      case EquityElementTypeEnum.TEXT:
        return EquityTextComponent;
      case EquityElementTypeEnum.MEDIA:
        return EquityMediaComponent;
      case EquityElementTypeEnum.TILES:
        return EquityTilesComponent;
      case EquityElementTypeEnum.CARDS:
        return EquityCardsComponent;
      case EquityElementTypeEnum.SPACER:
        return DynamicSpacerComponent;
      default:
        return EquityElementNotFoundComponent;
    }
  }

  public setNews(news: Topic): void {
    this.shownNews$.next(news);
    this.setFilteredNewsList();
  }

  public getLanguageContent(news: Topic): CommunicationContent {
    const enContent = news?.contents?.find(item => item.language === ContentLanguage.ENGLISH);
    const enContentText = JSON.parse(enContent?.text || '{}');
    if (enContentText['useCmsNews'] || (!enContentText['useOldNews'] && enContentText['cmsItemUsed'])) {
      return enContent;
    }
    const foundContent: CommunicationContent = news?.contents?.find(item => item.language === this.translateService?.currentLang);
    return foundContent || news?.contents?.find(item => item.language === ContentLanguage.ENGLISH);
  }

  private getCmsLanguageContent(news: Record<ContentLanguage, CmsContentItemContent>): CmsContentItemContent {
    const foundContent = news[this.translateService?.currentLang as ContentLanguage];
    return foundContent || news[ContentLanguage.ENGLISH];
  }

  private getLanguageElements(content: CommunicationContent): CommunicationContentElements {
    return content?.text?.length ? JSON.parse(content.text) : {};
  }
}
