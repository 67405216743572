<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>
        {{ data?.customHeaderTitle ?? 'season-mgmt.incentive.monitoring.letter-creation.requests' | translate }}
        {{ data?.result?.totalCount !== undefined ? ': ' : '' }}{{ data?.result?.totalCount }}
      </h3>
    </div>
    <div class="inner-content">
      <div class="overview">
        @if (data.customType) {
          @switch (data.customType) {
            @case (customType.DETAIL_COUNTER) {
              <div>
                <p class="bold">
                  <mat-icon>add</mat-icon>{{ 'season-mgmt.incentive.monitoring.letter-creation.created-count' | translate }}: {{ data?.result?.createdCount || 0 }}
                </p>
                <p class="bold">
                  <mat-icon>edit</mat-icon>{{ 'season-mgmt.incentive.monitoring.letter-creation.updated-count' | translate }}: {{ data?.result?.updatedCount || 0 }}
                </p>
                <p class="bold">
                  <mat-icon>redo</mat-icon>{{ 'season-mgmt.incentive.monitoring.letter-creation.skipped-count' | translate }}: {{ data?.result?.skippedCount || 0 }}
                </p>
              </div>
            }
            @case (customType.PLANS) {
              <div>
                <p class="bold">
                  <mat-icon>add</mat-icon>{{ 'season-mgmt.incentive.plan-management.new-plan-assignments-count' | translate }}: {{ data?.result?.newPlanAssignmentCount || 0 }}
                </p>
                <p class="bold">
                  <mat-icon>add</mat-icon>{{ 'season-mgmt.incentive.plan-management.created-reassignments-count' | translate }}: {{ data?.result?.createdReassignmentsCount || 0 }}
                </p>
                <p class="bold">
                  <mat-icon>edit</mat-icon>{{ 'season-mgmt.incentive.plan-management.updated-reassignments-count' | translate }}: {{ data?.result?.updatedReassignmentsCount || 0 }}
                </p>
              </div>
            }
            @case (customType.MOVE_ORG) {
              <div>
                <p class="bold"><mat-icon>edit</mat-icon>{{ 'org-planning.moved-positions' | translate }}: {{ data?.result?.movedPositions || 0 }}</p>
                <p class="bold"><mat-icon>edit</mat-icon>{{ 'org-planning.moved-positions-records' | translate }}: {{ data?.result?.movedPositionRecords || 0 }}</p>
                <p class="bold"><mat-icon>edit</mat-icon>{{ 'org-planning.moved-employee-records' | translate }}: {{ data?.result?.movedEmployeeRecords || 0 }}</p>
                <p class="bold"><mat-icon>edit</mat-icon>{{ 'org-planning.moved-child-organisations' | translate }}: {{ data?.result?.movedChildOrganisations || 0 }}</p>
                <p class="bold">
                  <mat-icon>edit</mat-icon>{{ 'org-planning.moved-child-organisation-records' | translate }}: {{ data?.result?.movedChildOrganisationRecords || 0 }}
                </p>
                <p class="bold"><mat-icon>edit</mat-icon>{{ 'org-planning.moved-organisation-records' | translate }}: {{ data?.result?.movedOrganisationRecords || 0 }}</p>
              </div>
            }
            @case (customType.MOVE_POSITION) {
              <div>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'cosmos.move-positions-success-count' | translate }}:
                  {{ data?.result?.movedPositions ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'cosmos.move-employees-success-count' | translate }}:
                  {{ data?.result?.movedEmployees ?? 0 }}
                </p>
              </div>
            }
            @case (customType.DELETE_ORGANISATIONS) {
              <div>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-child-organisations-count' | translate }}:
                  {{ data?.result?.deletedChildOrganisations ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-positions-count' | translate }}:
                  {{ data?.result?.deletedPositions ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-job-assignments-count' | translate }}:
                  {{ data?.result?.deletedJobAssignments ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-child-organisation-records-count' | translate }}:
                  {{ data?.result?.deletedChildOrganisationRecords ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-position-records-count' | translate }}:
                  {{ data?.result?.deletedPositionRecords ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.deleted-job-assignment-records-count' | translate }}:
                  {{ data?.result?.deletedJobAssignmentRecords ?? 0 }}
                </p>
              </div>
            }
            @case (customType.EDIT_ORGANISATION) {
              <div>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.updated-organisation-records-count' | translate }}:
                  {{ data?.result?.updatedOrganisationRecords ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.updated-organisations-count' | translate }}:
                  {{ data?.result?.updatedOrganisations ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.updated-positions-count' | translate }}:
                  {{ data?.result?.updatedPositions ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.updated-position-records' | translate }}:
                  {{ data?.result?.updatedPositionRecords ?? 0 }}
                </p>
              </div>
            }
            @case (customType.DUPLICATE_CHART) {
              <div>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.duplicated-organisations' | translate }}:
                  {{ data?.result?.duplicatedOrganisations ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.duplicated-positions' | translate }}:
                  {{ data?.result?.duplicatedPositions ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.duplicated-job-assignments' | translate }}:
                  {{ data?.result?.duplicatedJobAssignments ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning.duplicated-employees' | translate }}:
                  {{ data?.result?.duplicatedEmployees ?? 0 }}
                </p>
              </div>
            }
            @case (customType.CALCULATE_ORG_GUIDELINE_VIOLATIONS) {
              <div>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning-support.detected-violations' | translate }}:
                  {{ data?.result?.detectedViolations ?? 0 }}
                </p>
                <p class="bold">
                  <mat-icon>check_circle</mat-icon>{{ 'org-planning-support.removed-violations' | translate }}:
                  {{ data?.result?.removedViolations ?? 0 }}
                </p>
              </div>
            }
          }
        }
        @if (!data.customType) {
          <p class="bold">
            <mat-icon>check_circle</mat-icon>{{ 'season-mgmt.incentive.monitoring.letter-creation.success-count' | translate }}:
            {{ data?.result?.successCount ?? getAccumulatedSuccessCount(data?.result) ?? 0 }}
            <mat-icon class="skipped">privacy_tip</mat-icon>{{ 'season-mgmt.incentive.monitoring.letter-creation.skipped-count' | translate }}:
            {{ data?.result?.skippedCount ?? data?.result?.skipCount ?? 0 }}
          </p>
        }
        <p class="bold">
          <mat-icon>sms_failed</mat-icon>{{ data?.customFailureCountTitle ?? 'season-mgmt.incentive.monitoring.letter-creation.failure-count' | translate }}:
          {{ data?.result?.failureCount ?? data?.result?.failedCount ?? (!!data?.result?.failureReason ? 1 : 0) }}
          @if (data.result?.warningsCount) {
            <mat-icon class="expand-icon" (click)="toggleFailuresView()">{{ areFailuresVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          }
        </p>
      </div>
      <div class="details">
        <div>
          @if (!data.customType) {
            <div class="successes">
              @for (item of data?.result?.modifiedEntities; track item; let i = $index) {
                <div class="mat-elevation-z2">
                  <p>{{ i + 1 }}: {{ item | pure: getItemOverviewText }}</p>
                </div>
              }
            </div>
          }
        </div>
        <div>
          @if (areFailuresVisible) {
            <div class="failures">
              @for (item of data?.result?.failures; track item; let i = $index) {
                <div class="mat-elevation-z2">
                  <p>{{ i + 1 }}: {{ item | pure: getItemOverviewText }}</p>
                </div>
              }
            </div>
          }
          @if (data?.result?.warningsCount) {
            <p class="bold summary-topic">
              <mat-icon>warning</mat-icon>{{ data?.customFailureCountTitle ?? 'season-mgmt.incentive.monitoring.letter-creation.warning-count' | translate }}:
              {{ data?.result?.warningsCount }}
              @if (data.result?.warningsCount) {
                <mat-icon class="expand-icon" (click)="toggleWarningsView()">{{ areWarningsVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
              }
            </p>
            @if (areWarningsVisible) {
              <div class="warnings">
                @for (warning of data?.result?.warnings; track warning; let i = $index) {
                  <div class="mat-elevation-z2">
                    <p>{{ i + 1 }}: {{ warning | pure: getItemOverviewText }}</p>
                  </div>
                }
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
  <div>
    <coin-v2-dialog-footer color="white" padding="large">
      <div class="v2-footer-spacer"></div>
      <coin-action-button class="secondary" svgIcon="cancel" (click)="close()">
        {{ 'general.btnClose' | translate }}
      </coin-action-button>
    </coin-v2-dialog-footer>
  </div>
</div>
