/* eslint-disable no-case-declarations */
import { MeritSeasonSetting } from '@coin/admin/season-mgmt/util';
import { MeritBudgetComponentCalculationType, MeritPromotionState } from '@coin/customer/merit-incentive/util';
import { BudgetComponentType } from '@coin/shared/util-enums';
import { RoundOperations } from '@coin/shared/util-helpers';
import { MeritBudgetComponent } from '../models/merit-budget-component.model';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';

export class MeritBudgetCalculationOperations {
  // commonly used gets
  public static getEquityComponent(item: EmployeeWithCompensationInfo): MeritBudgetComponent {
    return item?.compensationComponents?.find(component => component.type === BudgetComponentType.Equity);
  }

  public static getTotalTargetCashComponent(item: EmployeeWithCompensationInfo): MeritBudgetComponent {
    return item?.compensationComponents?.find(component => component.type === BudgetComponentType.TotalTargetCash);
  }

  public static getBaseSalaryComponent(item: EmployeeWithCompensationInfo): MeritBudgetComponent {
    return item?.compensationComponents?.find(component => component.type === BudgetComponentType.BaseSalary);
  }

  public static getOneTimePaymentComponent(item: EmployeeWithCompensationInfo): MeritBudgetComponent {
    return item?.compensationComponents?.find(component => component.type === BudgetComponentType.OneTimePayment);
  }

  public static getBudgetComponentByType(item: EmployeeWithCompensationInfo, type: BudgetComponentType): MeritBudgetComponent {
    return item?.compensationComponents?.find(component => component.type === type);
  }

  public static areAllBudgetsMaintained(flatListOfComponents: MeritBudgetComponent[]): boolean {
    return flatListOfComponents?.every(comp => {
      if (comp.type === BudgetComponentType.TotalTargetCash) {
        return !!comp.totalAmount;
      }

      return true;
    });
  }

  public static isTTCComponentChildInDetailsView(component: MeritBudgetComponent): boolean {
    return [
      BudgetComponentType.AllowancesPercentageInTotal,
      BudgetComponentType.AnnualPayment,
      BudgetComponentType.BasePay,
      BudgetComponentType.BaseSalary,
      BudgetComponentType.FlatAllowances,
      BudgetComponentType.TargetIncentiveAmount
    ].includes(component.type);
  }

  public static isTTCDependentChildInCalc(component: MeritBudgetComponent): boolean {
    return [
      BudgetComponentType.AllowancesPercentageInTotal,
      BudgetComponentType.AnnualPayment,
      BudgetComponentType.BaseSalary,
      BudgetComponentType.FlatAllowances,
      BudgetComponentType.TargetIncentiveAmount
    ].includes(component.type);
  }

  public static isTTCChildAndEditable(component: MeritBudgetComponent): boolean {
    return [BudgetComponentType.BaseSalary, BudgetComponentType.BasePay].includes(component.type);
  }

  public static copySliderReferenceValuesOfTTC(ttcComponent: MeritBudgetComponent, component: MeritBudgetComponent): void {
    if (this.isTTCChildAndEditable(component)) {
      if (!component.minTotalValue) {
        component.minTotalValue = ttcComponent.minTotalValue;
      }
      if (!component.minPercentageValue) {
        component.minPercentageValue = ttcComponent.minPercentageValue;
      }
      if (!component.orientationPercentageValue) {
        component.orientationPercentageValue = ttcComponent.orientationPercentageValue;
      }
      if (!component.maximumPercentageValue) {
        component.maximumPercentageValue = ttcComponent.maximumPercentageValue;
      }
    }
  }

  public static automaticallyMaintainTotalValues(flatListOfComponents: MeritBudgetComponent[]): void {
    const ttcComponent = flatListOfComponents?.find(component => component.type === BudgetComponentType.TotalTargetCash);

    if (ttcComponent) {
      this.setMinIncreaseOfComponentIfNecessary(ttcComponent);
      this.processChangedComponentTotalAmount(ttcComponent, flatListOfComponents);
    }
  }

  private static setMinIncreaseOfComponentIfNecessary(component: MeritBudgetComponent): void {
    if (component.minTotalValue && component.minTotalValue > component.totalAmount) {
      component.totalAmount = component.minTotalValue;
    } else if (component.currentAmount > component.totalAmount) {
      component.totalAmount = component.currentAmount;
    }
  }

  public static validateMinIncreaseBaseSalary(components: MeritBudgetComponent[], editedComponent: MeritBudgetComponent): boolean {
    if (![BudgetComponentType.Equity, BudgetComponentType.OneTimePayment].includes(editedComponent.type)) {
      const baseSalaryComponent = components?.find(component => component.type === BudgetComponentType.BaseSalary);
      const minIncreaseAmount = RoundOperations.round((baseSalaryComponent.minPercentageValue / 100) * baseSalaryComponent.currentAmount + baseSalaryComponent.currentAmount, 2);

      return baseSalaryComponent ? baseSalaryComponent.totalAmount >= minIncreaseAmount : true;
    }
    return true;
  }

  public static processChangedComponentTotalAmount(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    this.processChangedComponent(changedComponent, flatListOfComponents);
    this.processDependentComponentsIfSpecialHandlingIsNeeded(changedComponent, flatListOfComponents);
    this.processDeltaComponents(changedComponent, flatListOfComponents);
    this.setNoIncreaseValuesIfNecessary(changedComponent, flatListOfComponents);
    this.calcBasePay(flatListOfComponents);
    this.calcTTC(flatListOfComponents);
    this.calcFixedAllowances(flatListOfComponents);
  }

  public static isInPromotionProcess(promotionState: MeritPromotionState): boolean {
    return [MeritPromotionState.Requested, MeritPromotionState.InProgress, MeritPromotionState.Finalized].includes(promotionState);
  }

  public static isOrientationValueExceeded({
    increasePercentage,
    orientationPercentage,
    seasonSettings,
    promotionState
  }: {
    increasePercentage: number;
    orientationPercentage: number;
    seasonSettings: MeritSeasonSetting;
    promotionState: MeritPromotionState;
  }): boolean {
    const defaultFactor = 1;
    const excessFactor = this.isInPromotionProcess(promotionState) ? seasonSettings?.promotionOrientationValueExcessFactor : seasonSettings?.orientationValueExcessFactor;

    return increasePercentage > orientationPercentage * (excessFactor || defaultFactor);
  }

  private static processChangedComponent(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    const component = flatListOfComponents.find(comp => comp.type === changedComponent.type);

    component.totalAmount = RoundOperations.round(changedComponent.totalAmount, 2);
    component.percentageOfBase = changedComponent?.percentageOfBase;

    if (component.type === BudgetComponentType.Equity) component.localTotalAmount = RoundOperations.round(changedComponent.localTotalAmount, 2);
  }

  private static processDependentComponentsIfSpecialHandlingIsNeeded(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    switch (changedComponent.type) {
      case BudgetComponentType.TotalTargetCash:
        this.processTTCChange(changedComponent, flatListOfComponents);
        break;
      case BudgetComponentType.BaseSalary:
        this.processBaseSalaryChange(flatListOfComponents);
        break;
      case BudgetComponentType.BasePay:
        this.processBasePayChange(changedComponent, flatListOfComponents);
        break;
      case BudgetComponentType.TargetIncentiveAmount:
        if (changedComponent.calculationType === MeritBudgetComponentCalculationType.PercentageOfBase) {
          this.processTIAPoBChange(flatListOfComponents);
        }
        break;
    }
  }

  private static processDeltaComponents(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    if (changedComponent.calculationType === MeritBudgetComponentCalculationType.Delta) {
      const deltaComponents = flatListOfComponents.filter(comp => comp.calculationType === MeritBudgetComponentCalculationType.Delta && this.isTTCDependentChildInCalc(comp));

      for (const deltaComponent of deltaComponents) {
        if (deltaComponent.totalAmount < deltaComponent.currentAmount) {
          deltaComponent.totalAmount = deltaComponent.currentAmount;

          this.processDependentComponentsIfSpecialHandlingIsNeeded(deltaComponent, flatListOfComponents);
        }
      }
    }
  }

  private static recalculatePobComponents(flatListOfComponents: MeritBudgetComponent[]): void {
    const baseSalaryComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.BaseSalary);
    const pobComponents = flatListOfComponents.filter(comp => comp.calculationType === MeritBudgetComponentCalculationType.PercentageOfBase);
    for (const pobComponent of pobComponents) {
      //   If the pob of the component is 0 try to calculate the actual percentage on the fly, because the internal state can contain a falsely returned 0 from BE
      const pob = pobComponent.percentageOfBase || pobComponent.currentAmount / baseSalaryComponent.currentAmount || 0;
      pobComponent.totalAmount = RoundOperations.round(pob * (baseSalaryComponent.totalAmount || baseSalaryComponent.currentAmount), 2);
    }
  }

  private static processTTCChange(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    const absoluteIncrease = changedComponent.totalAmount - changedComponent.currentAmount;

    const deltaComponents = flatListOfComponents.filter(comp => comp.calculationType === MeritBudgetComponentCalculationType.Delta && this.isTTCDependentChildInCalc(comp));
    const deltaComponentsWithoutBaseSalary = deltaComponents.filter(comp => comp.type !== BudgetComponentType.BaseSalary);
    const pobComponents = flatListOfComponents.filter(comp => comp.calculationType === MeritBudgetComponentCalculationType.PercentageOfBase);

    const sumOfChildrenTotalAmount = [...deltaComponents.filter(comp => comp.isEditable || !comp.isVisible), ...pobComponents].reduce(
      (acc, comp) => acc + (comp.totalAmount || comp.currentAmount),
      0
    );

    const baseSalaryComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.BaseSalary);

    if (baseSalaryComponent) {
      const proportion = sumOfChildrenTotalAmount ? (baseSalaryComponent.totalAmount || baseSalaryComponent.currentAmount) / sumOfChildrenTotalAmount : 0;
      const newBaseSalaryAmount = RoundOperations.round(proportion * absoluteIncrease + baseSalaryComponent.currentAmount, 2);
      const newBaseSalaryMinTotalAmount = (baseSalaryComponent.minTotalValue ?? 0) + baseSalaryComponent.currentAmount;

      baseSalaryComponent.totalAmount = newBaseSalaryAmount >= newBaseSalaryMinTotalAmount ? newBaseSalaryAmount : newBaseSalaryMinTotalAmount;
      this.recalculatePobComponents(flatListOfComponents);
    }

    if (deltaComponentsWithoutBaseSalary.length) {
      for (const component of deltaComponentsWithoutBaseSalary) {
        if (!component.isEditable && component.isVisible) {
          component.totalAmount = RoundOperations.round(component.currentAmount, 2);
          continue;
        }

        const proportion = sumOfChildrenTotalAmount ? (component.totalAmount || component.currentAmount) / sumOfChildrenTotalAmount : 0;
        component.totalAmount = RoundOperations.round(proportion * absoluteIncrease + component.currentAmount, 2);
      }
    }
  }

  private static processBasePayChange(baseSalaryComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    const absoluteIncrease = baseSalaryComponent.totalAmount - baseSalaryComponent.currentAmount;
    const dependentComponents = flatListOfComponents.filter(comp => [BudgetComponentType.BaseSalary, BudgetComponentType.AllowancesPercentageInTotal].includes(comp.type));
    const sumOfChildrenTotalAmount = dependentComponents.reduce((acc, comp) => acc + (comp.totalAmount || comp.currentAmount), 0);

    for (const component of dependentComponents) {
      const proportion = sumOfChildrenTotalAmount ? (component.totalAmount || component.currentAmount) / sumOfChildrenTotalAmount : 0;
      component.totalAmount = RoundOperations.round(proportion * absoluteIncrease + component.currentAmount, 2);
    }
    this.recalculatePobComponents(flatListOfComponents);
  }

  private static processBaseSalaryChange(flatListOfComponents: MeritBudgetComponent[]): void {
    this.recalculatePobComponents(flatListOfComponents);
  }

  private static processTIAPoBChange(flatListOfComponents: MeritBudgetComponent[]): void {
    const baseSalaryComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.BaseSalary);
    const pobComponents = flatListOfComponents.filter(comp => comp.calculationType === MeritBudgetComponentCalculationType.PercentageOfBase);
    for (const pobComponent of pobComponents) {
      pobComponent.totalAmount = RoundOperations.round(pobComponent.percentageOfBase * (baseSalaryComponent.totalAmount || baseSalaryComponent.currentAmount), 2);
    }
  }

  private static setNoIncreaseValuesIfNecessary(changedComponent: MeritBudgetComponent, flatListOfComponents: MeritBudgetComponent[]): void {
    if ([BudgetComponentType.Equity, BudgetComponentType.OneTimePayment].includes(changedComponent.type)) {
      return;
    }
    const noIncreaseComponents = flatListOfComponents.filter(component => component.calculationType === MeritBudgetComponentCalculationType.NoIncrease);

    for (const component of noIncreaseComponents) {
      if (component.totalAmount < component.currentAmount) {
        component.totalAmount = RoundOperations.round(component.currentAmount, 2);
      }
    }
  }

  private static calcBasePay(flatListOfComponents: MeritBudgetComponent[]): void {
    const basePayComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.BasePay);

    if (basePayComponent) {
      const amount = flatListOfComponents
        .filter(comp => [BudgetComponentType.BaseSalary, BudgetComponentType.AllowancesPercentageInTotal].includes(comp.type))
        .reduce((acc, comp) => acc + (comp.totalAmount || 0), 0);
      basePayComponent.totalAmount = RoundOperations.round(amount, 2);
    }
  }

  private static calcTTC(flatListOfComponents: MeritBudgetComponent[]): void {
    const ttcComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.TotalTargetCash);

    if (ttcComponent) {
      const amount = flatListOfComponents
        .filter(comp => this.isTTCDependentChildInCalc(comp))
        .reduce(
          (acc, comp) => (comp.type === BudgetComponentType.AnnualPayment ? acc + (comp.totalAmount || 0) * (comp.referenceTotalValue || 0) : acc + (comp.totalAmount || 0)),
          0
        );

      ttcComponent.totalAmount = RoundOperations.round(amount, 2);
    }
  }

  private static calcFixedAllowances(flatListOfComponents: MeritBudgetComponent[]): void {
    const fixedAllowancesComponent = flatListOfComponents.find(comp => comp.type === BudgetComponentType.FixedAllowances);

    if (fixedAllowancesComponent) {
      const amount = flatListOfComponents
        .filter(comp => [BudgetComponentType.AllowancesPercentageInTotal, BudgetComponentType.FlatAllowances].includes(comp.type))
        .reduce((acc, comp) => acc + (comp.totalAmount || 0), 0);
      fixedAllowancesComponent.totalAmount = RoundOperations.round(amount, 2);
    }
  }
}
