<div class="container" [ngClass]="{ 'calendar-modification': calendarModification }">
  <button
    class="no-style cat-items all-item"
    (click)="resetCategoryFilter()"
    [ngClass]="{ 'filter-enabled': !(topicsFilter$ | async)?.allItemsFilter }"
    aria-label="Show all Calendar Entries">
    <p>{{ 'season-overview.calendar.all' | translate }}</p>
  </button>
  @for (category of (topicsFilter$ | async)?.categories; track category) {
    <button
      class="no-style  cat-items border--{{ category.type }} {{ category.active && !(topicsFilter$ | async)?.allItemsFilter ? 'active active--' + category.type : '' }}"
      (click)="updateVisibleTopics(category.type)"
      aria-label="Filter Calendar">
      <p class="color--{{ category.type }}"><i class="material-icons icon--{{ category.type }}"></i> {{ 'season-overview.calendar.' + category.type | translate }}</p>
    </button>
  }
</div>
