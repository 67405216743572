<div class="container">
  <h4 class="top-header">Select an Image</h4>
  <div class="select-container">
    <p class="instruction-text">Either directly insert the image's URL or select one of the shown images.</p>
    <div class="inner-select-container">
      @if (!isLoading && !selectedImageUrl) {
        <div class="image-details">
          <p class="no-selection-text">... No image selected ...</p>
        </div>
      }
      @if (!isLoading && selectedImageUrl) {
        <div class="preview-image">
          <img [src]="selectedImageUrl" />
          <gmm-spacer></gmm-spacer>

          <div class="usage-preview-container">
            @if (cmsUsages) {
              <coin-a-cms-image-usages [usages]="cmsUsages" [isImageUsed]="isImageUsed"></coin-a-cms-image-usages>
            }
            @if (isUsageLoading) {
              <div class="usage-spinner-container">
                <mat-spinner></mat-spinner>
              </div>
            }
          </div>
        </div>
      }

      @if (isLoading) {
        <div class="loading-container">
          <mat-spinner></mat-spinner>
        </div>
      }

      <div class="select-or-search-thumbnail">
        <mat-form-field>
          <mat-label>{{ 'cms.image-url' | translate }}</mat-label>
          <input matInput aria-label="image url" [(ngModel)]="selectedImageUrl" [placeholder]="'https://...'" [required]="true" type="test" />
        </mat-form-field>

        <div class="scrollable-thumbnail-container">
          @for (imageUrl of thumbnailUrls; track imageUrl) {
            <div>
              <div class="thumbnail-container">
                <div (click)="selectImage(imageUrl)" class="thumbnail-img" [ngStyle]="{ background: 'url(' + imageUrl + ')' }"></div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="dialog-buttons-container">
      <coin-action-button (click)="fileInput.click()" class="secondary" svgIcon="upload" [noicon]="true">{{ 'general.btnUpload' | translate }}</coin-action-button>
      <coin-action-button
        (click)="calculateUsageClickHandler(selectedImageUrl)"
        [disabled]="!this.selectedImageUrl || this.isUsageAlreadyCalculated"
        class="secondary"
        svgIcon="list"
        [noicon]="true"
        >{{ 'cms.emails.calculate-usages' | translate }}</coin-action-button
      >
      <coin-action-button (click)="deleteImage(selectedImageUrl)" [disabled]="!this.selectedImageUrl" class="secondary" svgIcon="delete" [noicon]="true">{{
        'general.btnDelete' | translate
      }}</coin-action-button>
      <input hidden type="file" #fileInput (change)="uploadImage($event)" id="imageInput" accept="*" />
      <gmm-spacer></gmm-spacer>
      <coin-action-button (click)="discard()" class="secondary" svgIcon="cancel" [noicon]="true">{{ 'general.btnClose' | translate }}</coin-action-button>
      <coin-action-button (click)="confirm()" [disabled]="!selectedImageUrl" class="primary" svgIcon="save" [noicon]="true">{{
        'general.btnConfirm' | translate
      }}</coin-action-button>
    </div>
  </div>
</div>
