/* eslint-disable max-lines-per-function */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivityStreamChangedProperty,
  ActivityStreamClauseVersionLoadConfig,
  ActivityStreamEvaluationMemberLoadConfig,
  ActivityStreamEventOperation,
  ActivityStreamHeadcountApprovalPositionRequestLoadConfig,
  ActivityStreamHelper,
  ActivityStreamIndividualMultiplierLoadConfig,
  ActivityStreamItem,
  ActivityStreamItemEventType,
  ActivityStreamItemType,
  ActivityStreamLoadConfig,
  ActivityStreamMercerMasterDataLoadConfig,
  ActivityStreamMeritLoadConfig,
  ActivityStreamOrgPlanningEmployeeLoadConfig,
  ActivityStreamOrgPlanningOrganisationLoadConfig,
  ActivityStreamOrgPlanningPositionLoadConfig,
  ActivityStreamPropertyItemTypeMapping,
  ActivityStreamRecordLoadConfig,
  ActivityStreamSeasonLoadConfig,
  ActivityStreamSpecialPaymentProposalLoadConfig,
  ActivityStreamStandingPositionEvaluationLoadConfig,
  ActivityStreamSuccessionManagementTalentPoolLoadConfig,
  ActivityStreamTemplateVersionLoadConfig
} from '@coin/modules/activity-stream/util';
import { HttpHelpersService, LoadingService, OrganisationSnapshotService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { EmployeeSlim, PaginatedResult } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { forkJoin, from, lastValueFrom, Observable } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityStreamService {
  private readonly epochTicks = 621355968000000000; // for converting ticks to milliseconds
  private readonly ticksPerMillisecond = 10000;

  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private organisationSnapshotService: OrganisationSnapshotService,
    private translateService: TranslateService
  ) {}

  public getHeadcountApprovalPositionRequestActivityStreamItems(loadConfig: ActivityStreamHeadcountApprovalPositionRequestLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<ActivityStreamItem[]>(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/headcount-approval/position-requests/${loadConfig.positionRequestId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getSuccessionManagementActivityStreamItems(loadConfig: ActivityStreamSuccessionManagementTalentPoolLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient.get<ActivityStreamItem[]>(`${environment.api.baseUrl}/succession-management/partner/v1/activity-stream/talent-pools/${loadConfig.talentPoolId}`).pipe(
      mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
      this.httpHelpersService.handleError('Cannot get activity stream items'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  public getEvaluationMemberActivityStreamItems(loadConfig: ActivityStreamEvaluationMemberLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/position-evaluations/${loadConfig?.seasonId}/evaluation-members/${loadConfig?.memberId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getIndividualMultiplierActivityStreamItems(loadConfig: ActivityStreamIndividualMultiplierLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/merit/${loadConfig?.seasonId}/allocation-members/${loadConfig?.allocationMemberId}/individual-multipliers`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getMeritActivityStreamItems(loadConfig: ActivityStreamMeritLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/merit/${loadConfig?.seasonId}/allocation-members/${loadConfig?.allocationMemberId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getSpecialPaymentProposalActivityStreamItems(loadConfig: ActivityStreamSpecialPaymentProposalLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<ActivityStreamItem[]>(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/special-payment/${loadConfig?.seasonId}/record/${loadConfig?.recordId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getOrgPlanningOrganisationActivityStreamItems(loadConfig: ActivityStreamOrgPlanningOrganisationLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/organisation-records/${loadConfig?.organisationId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getOrgPlanningPositionActivityStreamItems(loadConfig: ActivityStreamOrgPlanningPositionLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/position-records/${loadConfig?.positionId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getOrgPlanningEmployeeActivityStreamItems(loadConfig: ActivityStreamOrgPlanningEmployeeLoadConfig): Observable<ActivityStreamItem[]> {
    this.loadingService.present();
    return this.httpClient
      .get<
        ActivityStreamItem[]
      >(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/organisation-planning/${loadConfig?.organisationChartId}/employee-records/${loadConfig?.employeeId}`)
      .pipe(
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getRecordActivityStream(loadConfig: ActivityStreamRecordLoadConfig, page = 1, size = 1000): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { page, size } });

    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/record/${loadConfig.recordId}`, { params })
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        this.loadingService.withLoadingScreen
      );
  }

  public getSeasonActivityStream(loadConfig: ActivityStreamSeasonLoadConfig, page = 1, size = 100): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { page, size } });
    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}/admin/api/v1/master/activity-streams/seasons/season/${loadConfig.seasonId}`, { params })
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        this.loadingService.withLoadingScreen
      );
  }

  public getClauseVersionActivityStream(loadConfig: ActivityStreamClauseVersionLoadConfig, page = 1, size = 100): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { page, size } });

    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(
        `${environment.api.baseUrl}/condition-library/customer/v1/clauses/${loadConfig.clauseId}/versions/${loadConfig.versionId}/activity-stream`,
        {
          params
        }
      )
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        this.loadingService.withLoadingScreen
      );
  }

  public getTemplateVersionActivityStream(loadConfig: ActivityStreamTemplateVersionLoadConfig, page = 1, size = 100): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { page, size } });

    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(
        `${environment.api.baseUrl}/condition-library/customer/v1/templates/${loadConfig.templateId}/versions/${loadConfig.versionId}/activity-stream`,
        {
          params
        }
      )
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        this.loadingService.withLoadingScreen
      );
  }

  public getMercerMasterDataActivityStreamItems(loadConfig: ActivityStreamMercerMasterDataLoadConfig, page = 1, size = 100): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { pagingPage: page, pagingSize: size } });

    this.loadingService.present();
    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}/mercer-standing/admin/api/v1/master/activity-streams/employee/${loadConfig.employeeId}`, { params })
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getStandingPositionEvaluationActivityStreamItems(loadConfig: ActivityStreamStandingPositionEvaluationLoadConfig, page = 1, size = 100): Observable<ActivityStreamItem[]> {
    const params = new HttpParams({ fromObject: { pagingPage: page, pagingSize: size } });

    this.loadingService.present();
    return this.httpClient
      .get<PaginatedResult<ActivityStreamItem>>(`${environment.api.baseUrl}/mercer-standing/admin/api/v1/master/activity-streams/employee/${loadConfig.employeeId}`, { params })
      .pipe(
        map(({ content }) => content),
        mergeMap(items => from(this.mapActivityStreamItems(items, loadConfig))),
        this.httpHelpersService.handleError('Cannot get activity stream items'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  private async mapActivityStreamItems(items: ActivityStreamItem[], loadConfig: ActivityStreamLoadConfig): Promise<ActivityStreamItem[]> {
    return (await Promise.all(items?.map(item => this.mapActivityStreamItem(item, loadConfig))))?.sort((a, b) => b.timestamp - a.timestamp);
  }

  private async mapActivityStreamItem(item: ActivityStreamItem, loadConfig: ActivityStreamLoadConfig): Promise<ActivityStreamItem> {
    return {
      ...item,
      itemType: this.getItemType(item),
      changedProperties: await this.getChangedProperties(item, loadConfig),
      eventBy: this.getEventBy(item),
      eventAt: this.getEventAt(item)
    };
  }

  private getItemType(item: ActivityStreamItem): ActivityStreamItemType {
    if (item.eventType === ActivityStreamItemEventType.JobAssignmentProposal) {
      return ActivityStreamItemType.ShortlistChanged;
    }

    if (item.eventType === ActivityStreamItemEventType.SpecialPaymentApproval) {
      if (item.changedProperties?.length === 1 && item.changedProperties[0].propertyName === 'ExpectedApproverId') {
        return ActivityStreamItemType.ApproverChanged;
      }
      return ActivityStreamItemType.StatusChanged;
    }

    if (item.eventType === ActivityStreamItemEventType.PositionRequest) {
      return ActivityStreamItemType.InquiryApprovalProposal;
    }

    if (item.eventType === ActivityStreamItemEventType.PositionRequestApproval) {
      return ActivityStreamItemType.InquiryApprovalStep;
    }

    if (item.eventType === ActivityStreamItemEventType.EmployeeSnapshotManagerChanged) {
      return ActivityStreamItemType.ManagerChanged;
    }

    if (item.changedProperties?.some(property => ['ManuallyChanged', 'NewHire', 'Promotion', 'Termination', 'PopulationChange'].includes(property.propertyName))) {
      return ActivityStreamItemType.StatusChanged;
    }

    if (item.changedProperties?.some(property => ['MeritSettingsChanged', 'EquitySettingsChanged', 'EquitySubSettingsChanged'].includes(property.propertyName))) {
      return ActivityStreamItemType.PlanChanged;
    }

    if (item.eventType === ActivityStreamItemEventType.PositionRecord) {
      if (item.eventOperation === ActivityStreamEventOperation.Created) {
        return ActivityStreamItemType.CreatedPosition;
      }
      if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
        return ActivityStreamItemType.DeletedPosition;
      }
      if (item.eventOperation === ActivityStreamEventOperation.Updated) {
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
          return ActivityStreamItemType.MovedPositionBetweenOrgCharts;
        }
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'Title', 'OrgCode')) {
          return ActivityStreamItemType.MovedPosition;
        }
        if (item.changedProperties?.length === 1 && item.changedProperties?.some(property => property.propertyName === 'IsHead')) {
          if (item.changedProperties[0].currentValue === 'True') {
            return ActivityStreamItemType.SetAsHeadPosition;
          }
          return ActivityStreamItemType.RemovedAsHeadPosition;
        }
      }
    }

    if (item.eventType === ActivityStreamItemEventType.EmployeeRecord) {
      if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
        return ActivityStreamItemType.MovedEmployeeBetweenOrgCharts;
      }
    }

    if (item.eventType === ActivityStreamItemEventType.EmployeeRecord) {
      if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
        return ActivityStreamItemType.MovedEmployeeBetweenOrgCharts;
      }
    }

    if (item.eventType === ActivityStreamItemEventType.JobAssignmentRecordPositionRecord) {
      if (ActivityStreamHelper.hasSomePropertyChanged(item, 'EmployeeId')) {
        const employeeIdProperty = ActivityStreamHelper.getProperty(item, 'EmployeeId');
        // Current BE behaviour, when both current and original values are set it is considered an unassignment
        if (employeeIdProperty.currentValue && employeeIdProperty.originalValue) {
          return ActivityStreamItemType.UnassignedEmployee;
        }
        if (!employeeIdProperty.originalValue) {
          return ActivityStreamItemType.AssignedEmployee;
        }
      }
    }

    if (item.eventType === ActivityStreamItemEventType.JobAssignmentRecordEmployeeRecord) {
      if (ActivityStreamHelper.hasSomePropertyChanged(item, 'EmployeeId')) {
        if (item.eventOperation === ActivityStreamEventOperation.Created) {
          return ActivityStreamItemType.AssignedPosition;
        }
        if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
          return ActivityStreamItemType.UnassignedPosition;
        }
      }
    }

    if (item.eventType === ActivityStreamItemEventType.OrganisationRecord) {
      if (item.changedProperties.every(property => !property.originalValue)) {
        return ActivityStreamItemType.CreatedOrganisation;
      }
      if (ActivityStreamHelper.hasSomePropertyChanged(item, 'OrganisationChartId')) {
        return ActivityStreamItemType.MovedOrganisationBetweenOrgCharts;
      }
      if (item.changedProperties.some(property => property.propertyName === 'ParentOrgCode')) {
        return ActivityStreamItemType.MovedOrganisation;
      }
      if (item.changedProperties.some(property => ['OrgCode', 'OrgName', 'OrgType'].includes(property.propertyName))) {
        return ActivityStreamItemType.ChangedInformation;
      }
    }

    if (item.eventType === ActivityStreamItemEventType.PositionOrganisationAssignmentChanged) {
      return ActivityStreamItemType.OrganisationChanged;
    }

    if (
      [
        ActivityStreamItemEventType.AutoApproveAllowedChanged,
        ActivityStreamItemEventType.ApprovalRequiredChanged,
        ActivityStreamItemEventType.PayrollStateReset,
        ActivityStreamItemEventType.PayrollExported,
        ActivityStreamItemEventType.SeasonCompensationOverlaid,
        ActivityStreamItemEventType.SeasonBenchmarkUpdateTriggered,
        ActivityStreamItemEventType.EmployeeSnapshotDataUpdated,
        ActivityStreamItemEventType.MercerDataTransferred
      ].includes(item.eventType)
    ) {
      return item.eventType as unknown as ActivityStreamItemType;
    }

    if (item.eventType === ActivityStreamItemEventType.Record) {
      if (ActivityStreamHelper.getProperty(item, 'PayrollState')?.currentValue === 'Done') {
        return ActivityStreamItemType.PayrollExported;
      }
      if (ActivityStreamHelper.getProperty(item, 'PayrollState')?.currentValue === 'Open') {
        return ActivityStreamItemType.PayrollStateReset;
      }
    }

    // Succession Management
    if (item.eventType === ActivityStreamItemEventType.TalentRecommendation) {
      if (item.eventOperation === ActivityStreamEventOperation.Created) {
        return ActivityStreamItemType.NewRecommendationAdded;
      }
      if (item.eventOperation === ActivityStreamEventOperation.Updated) {
        // Talentpool assignment
        if (ActivityStreamHelper.hasSomePropertyChanged(item, 'TalentPoolId')) {
          return ActivityStreamItemType.InformationChanged;
        }

        // RecommendationState
        const originalValue = ActivityStreamHelper.getProperty(item, 'RecommendationState')?.originalValue;
        const currentValue = ActivityStreamHelper.getProperty(item, 'RecommendationState')?.currentValue;
        if (this.isRevertedRecommendation(originalValue as string, currentValue as string)) {
          return ActivityStreamItemType.RevertedRecommendation;
        }
        return ActivityStreamItemType.StateChanged;
      }

      if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
        return ActivityStreamItemType.TalentRemovedFromPool;
      }
    }

    if (item.eventType === ActivityStreamItemEventType.TalentPoolAssignment) {
      if (item.eventOperation === ActivityStreamEventOperation.Created) {
        if (!ActivityStreamHelper.getProperty(item, 'RecommendationState')) {
          return ActivityStreamItemType.NewTalentAddedToPool;
        }
        return ActivityStreamItemType.NewRecommendationAdded;
      }
      if (item.eventOperation === ActivityStreamEventOperation.Deleted) {
        return ActivityStreamItemType.TalentRemovedFromPool;
      }
    }

    if (item.eventType === ActivityStreamItemEventType.TemplateVersionFooterTranslation) {
      return ActivityStreamItemType.FooterTranslationChanged;
    }

    if (item.eventType === ActivityStreamItemEventType.StandingPositionEvaluationRequest) {
      return ActivityStreamItemType.EvaluationRequest;
    }

    if (item.eventType === ActivityStreamItemEventType.StandingPositionEvaluationRequestApproval) {
      return ActivityStreamItemType.MasterDataTransfer;
    }

    if (item.eventType === ActivityStreamItemEventType.StandingPositionEvaluationMasterDataJobCode) {
      return ActivityStreamItemType.MasterDataJobCode;
    }

    if (item.eventType === ActivityStreamItemEventType.StandingPositionEvaluationMasterDataPositionClass) {
      return ActivityStreamItemType.MasterDataPositionClass;
    }

    if (item.eventType === ActivityStreamItemEventType.StandingPositionEvaluationMasterDataEmployee) {
      return ActivityStreamItemType.MasterDataEmployee;
    }

    return !item.changedProperties?.length || item.changedProperties?.length > 1
      ? ActivityStreamItemType.InformationChanged
      : (ActivityStreamPropertyItemTypeMapping[item.changedProperties[0].propertyName] ?? ActivityStreamItemType.InformationChanged);
  }

  private isRevertedRecommendation(originalValue: string, currentValue: string): boolean {
    const stateValues = ['Approved', 'Declined', 'ReviewerAccepted', 'ReviewerDeclined', 'Recommended'];
    const originalIndex = stateValues.indexOf(originalValue);
    const currentIndex = stateValues.indexOf(currentValue);
    return originalIndex < currentIndex;
  }

  private async getChangedProperties(item: ActivityStreamItem, loadConfig: ActivityStreamLoadConfig): Promise<ActivityStreamChangedProperty[]> {
    // special cases
    switch (this.getItemType(item)) {
      case ActivityStreamItemType.ShortlistChanged:
        return item.changedProperties?.filter(property => property.propertyName === 'EmployeeId');
      case ActivityStreamItemType.OrganisationChanged: {
        const changedProperty = item.changedProperties?.filter(property => property.propertyName === 'OrganisationSnapshotId')?.[0];
        if (changedProperty) {
          const { seasonId } = loadConfig as ActivityStreamEvaluationMemberLoadConfig;
          return lastValueFrom(
            forkJoin(
              [changedProperty.originalValue, changedProperty.currentValue].map((organisationSnapshotId: string) =>
                this.organisationSnapshotService.getOrganisationSnapshot(seasonId, organisationSnapshotId)
              )
            ).pipe(map(([original, current]) => [{ propertyName: 'OrganisationSnapshotId', originalValue: original?.orgCode, currentValue: current?.orgCode }]))
          );
        }
      }
    }

    if (item.eventType === ActivityStreamItemEventType.PositionRequest)
      return item.changedProperties.map(changedProperty => {
        const translationKeyPrefix = 'headcount-approval.contract-type-';
        if (changedProperty.propertyName === 'ContractType') {
          return {
            propertyName: changedProperty.propertyName,
            originalValue: changedProperty.originalValue ? `${translationKeyPrefix}${String(changedProperty.originalValue).toLowerCase()}` : changedProperty.originalValue,
            currentValue: changedProperty.currentValue ? `${translationKeyPrefix}${String(changedProperty.currentValue).toLowerCase()}` : changedProperty.currentValue
          };
        }

        if (changedProperty.propertyName === 'PositionRequestState') {
          const originalStepName = changedProperty?.originalValue as string;
          const currentStepName = changedProperty?.currentValue as string;
          return {
            propertyName: changedProperty.propertyName,
            originalValue: originalStepName ? this.translateService.instant(`activity-stream.inquiry-approval-process.steps.position-request-approval.${originalStepName}`) : '-',
            currentValue: this.translateService.instant(`activity-stream.inquiry-approval-process.steps.position-request-approval.${currentStepName}`)
          };
        }

        return changedProperty;
      });

    return item.changedProperties;
  }

  private getEventBy(item: ActivityStreamItem): EmployeeSlim {
    const isSpecialEventType =
      item.eventType === ActivityStreamItemEventType.JobAssignmentRecordPositionRecord || item.eventType === ActivityStreamItemEventType.JobAssignmentRecordEmployeeRecord;
    const isCreatedOperation = item?.eventOperation === ActivityStreamEventOperation.Created;

    if (isSpecialEventType && isCreatedOperation) {
      return item?.createdBy || item?.updatedBy;
    }

    return isCreatedOperation ? item?.createdBy : item?.updatedBy;
  }

  private getEventAt(item: ActivityStreamItem): string {
    const ticksSinceEpoch = item.timestamp - this.epochTicks;
    const millisecondsSinceEpoch = ticksSinceEpoch / this.ticksPerMillisecond;

    return moment(millisecondsSinceEpoch).toISOString();
  }
}
